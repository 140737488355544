"use client";
import React, { useEffect, useState } from "react";
import { useJsApiLoader } from "@react-google-maps/api";
import { Controller, useForm } from "react-hook-form";
import { Label } from "@/components/ui/label";
import Select, { MultiValue } from "react-select";
import AsyncSelect from "react-select/async";
import Loading from "@/components/Loading";
import { getWorkShiftPreferences } from "@/ajax/shift_preferences/getWorkShiftPreferences";
import { ShiftContractDuration } from "@/common/enums/ShiftContractDuration";
import { updateWorkShiftPreferences } from "@/ajax/shift_preferences/updateWorkShiftPreferences";
import { WorkShiftSearchStatus } from "@/common/enums/WorkShiftSearchStatus";
import { Check } from "lucide-react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { queryClient } from "@/ajax/queryClient";
import { Checkbox } from "@/components/ui/checkbox";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { getEnvVar } from "@/common/utils/environment";
import { Switch } from "@/components/ui/switch";

type Inputs = {
  preferredFacilities: string[];
  preferredDurationId: ShiftContractDuration | null;
  preferredCities: {
    value: string;
    label: string;
  }[];
  outsidePreferredCities: boolean;
  workShiftSearchStatusId: WorkShiftSearchStatus;
  minHourlyRate: number | null;
};

const durationPreferences = [
  { value: null, label: "No Preference" },
  { value: 1, label: "Occasional Shifts" },
  { value: 2, label: "Contract (6-24 months)" },
  { value: 3, label: "Permanent" },
];

const workShiftSearchStatus = [
  [1, "Actively looking"],
  [2, "Passively looking"],
  [3, "Not looking for new jobs"],
].map((state) => ({
  value: state[0],
  label: state[1],
}));

export default function ShiftPreferencesForm({
  onComplete,
}: {
  onComplete?: () => void;
}) {
  const [justSaved, setJustSaved] = useState<boolean>(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const { data: result, isLoading } = useQuery({
    queryKey: ["shiftPreferences"],
    queryFn: getWorkShiftPreferences,
  });
  const { mutate, isPending } = useMutation({
    mutationFn: updateWorkShiftPreferences,
    onSuccess: () => {
      setJustSaved(true);
      setTimeout(() => setJustSaved(false), 3000);
      // Invalidate and refetch
      queryClient.invalidateQueries({ queryKey: ["shiftPreferences"] });
      onComplete && onComplete();
    },
  });

  useEffect(() => {
    const data = result;
    if (data) {
      reset({
        preferredCities: data.cities.map((val) => ({
          value: val,
          label: val,
        })),
        preferredFacilities: data.facility_types.map((type) => type.name),
        preferredDurationId: data.contract_duration_id,
        outsidePreferredCities: data.open_to_other_cities,
        workShiftSearchStatusId: data.search_status_id,
        minHourlyRate: data.min_hourly_rate || null,
      });
    }
  }, [result]);

  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: getEnvVar("VITE_PUBLIC_GOOGLE_MAPS_API_KEY") || "",
  });

  const {
    register,
    handleSubmit,
    setValue,
    control,
    watch,
    reset,
    formState: { errors, isSubmitting, isSubmitted },
  } = useForm<Inputs>({
    defaultValues: {
      preferredFacilities: [],
      preferredDurationId: null,
      preferredCities: [],
      outsidePreferredCities: true,
      workShiftSearchStatusId: WorkShiftSearchStatus.ACTIVELY_LOOKING,
      minHourlyRate: null,
    },
    mode: "onSubmit",
  });

  const lookingForWork =
    watch("workShiftSearchStatusId") !== WorkShiftSearchStatus.NOT_LOOKING;

  register("preferredDurationId");

  const onSubmit = async (data: Inputs) => {
    mutate({
      preferredFacilities: data.preferredFacilities,
      shiftContractDurationId: data.preferredDurationId,
      preferredCities: data.preferredCities.map((city) => city.value),
      openToOtherCities: data.outsidePreferredCities,
      searchStatusId: data.workShiftSearchStatusId,
      minHourlyRate: data.minHourlyRate,
    });
  };

  const cityOptions = async (inputValue: string) => {
    // @ts-ignore
    const { AutocompleteSessionToken, AutocompleteSuggestion } =
      (await google.maps.importLibrary("places")) as google.maps.PlacesLibrary;

    // Add an initial request body.
    let request = {
      input: inputValue,
      includedPrimaryTypes: ["(cities)"],
      language: "en-US",
      region: "us",
    };

    // Create a session token.
    const token = new AutocompleteSessionToken();
    // Add the token to the request.
    // @ts-ignore
    request.sessionToken = token;
    // Fetch autocomplete suggestions.
    const { suggestions } =
      await AutocompleteSuggestion.fetchAutocompleteSuggestions(request);

    return suggestions.map((suggestion) => {
      return {
        value: suggestion.placePrediction?.text.toString() ?? "",
        label: suggestion.placePrediction?.text.toString() ?? "",
      };
    });
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center w-full">
        <Loading className="w-20 h-20" />
      </div>
    );
  }

  return (
    <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
      <div className="mb-4.5">
        <Label htmlFor="state_input">Job Search Status</Label>
        {errors.workShiftSearchStatusId && (
          <span className="text-red-500 text-sm">
            {errors.workShiftSearchStatusId.message}
          </span>
        )}
        <Controller
          control={control}
          name="workShiftSearchStatusId"
          rules={{
            required: "Job search status is required",
          }}
          render={({ field }) => {
            return (
              <Select
                ref={field.ref}
                placeholder="Select Duration Preference"
                options={workShiftSearchStatus}
                classNames={{
                  control: (state) => "py-2",
                }}
                value={workShiftSearchStatus.find(
                  (option) => option.value === field.value,
                )}
                onChange={(
                  selectedOption: {
                    value: number | string;
                    label: string | number;
                  } | null,
                ) => {
                  field.onChange(
                    Number(selectedOption?.value) ??
                      WorkShiftSearchStatus.ACTIVELY_LOOKING,
                  );
                }}
              />
            );
          }}
        />
      </div>
      {lookingForWork && (
        <>
          <div className="mb-4.5">
            <Label htmlFor="minimum_rate">Minimum Hourly Rate (USD)</Label>
            <div className="relative">
              <span className="absolute left-3 top-1/2 transform -translate-y-1/2">
                $
              </span>
              <Controller
                control={control}
                name="minHourlyRate"
                rules={{
                  min: {
                    value: 0,
                    message: "Rate cannot be negative",
                  },
                }}
                render={({ field }) => (
                  <Input
                    type="number"
                    inputMode="decimal"
                    pattern="[0-9]*"
                    id="minimum_rate"
                    className="pl-7"
                    placeholder="0.00"
                    {...field}
                    value={field.value === null ? "" : field.value}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (value === "") {
                        field.onChange(null);
                      } else {
                        const numValue = Number(value);
                        if (!isNaN(numValue)) {
                          field.onChange(numValue);
                        }
                      }
                    }}
                  />
                )}
              />
            </div>
            {errors.minHourlyRate && (
              <span className="text-red-500 text-sm">
                {errors.minHourlyRate.message}
              </span>
            )}
          </div>
          <div className="mb-4.5">
            <Label htmlFor="email_input" className="mb-2 block">
              Preferred Facilities
            </Label>
            {errors.preferredFacilities && (
              <span className="text-red-500 text-sm">
                {errors.preferredFacilities.message}
              </span>
            )}
            {[
              "Medical Spa",
              "Urgent Care",
              "Hospital",
              "Nursing Home",
              "Rehab Center",
              "Telemedicine",
              "Dialysis center",
              "IV infusion Center",
              "Concierge Medicine",
            ].map((facilityType) => (
              <Controller
                key={facilityType}
                render={({ field }) => {
                  return (
                    <div className="mb-3 flex items-center gap-2">
                      {isMobile ? (
                        <>
                          <Label
                            className="font-normal flex-grow"
                            htmlFor={facilityType.replace(/\s/g, "")}
                          >
                            {facilityType}
                          </Label>
                          <Switch
                            id={facilityType.replace(/\s/g, "")}
                            checked={field.value.includes(facilityType)}
                            onCheckedChange={(checked) => {
                              if (checked) {
                                field.onChange([...field.value, facilityType]);
                              } else {
                                field.onChange(
                                  field.value.filter(
                                    (value) => value !== facilityType,
                                  ),
                                );
                              }
                            }}
                          />
                        </>
                      ) : (
                        <>
                          <Checkbox
                            id={facilityType.replace(/\s/g, "")}
                            value={facilityType}
                            checked={field.value.includes(facilityType)}
                            onCheckedChange={(checked: boolean) => {
                              if (checked) {
                                field.onChange([...field.value, facilityType]);
                              } else {
                                field.onChange(
                                  field.value.filter(
                                    (value) => value !== facilityType,
                                  ),
                                );
                              }
                            }}
                          />
                          <Label
                            className="font-normal"
                            htmlFor={facilityType.replace(/\s/g, "")}
                          >
                            {facilityType}
                          </Label>
                        </>
                      )}
                    </div>
                  );
                }}
                name="preferredFacilities"
                control={control}
              />
            ))}
          </div>
          <div className="mb-4.5">
            <Label htmlFor="state_input">Shift Duration Preference</Label>
            {errors.preferredDurationId && (
              <span className="text-red-500 text-sm">
                {errors.preferredDurationId.message}
              </span>
            )}
            <Controller
              control={control}
              name="preferredDurationId"
              render={({ field }) => {
                return (
                  <Select
                    ref={field.ref}
                    placeholder="Select Duration Preference"
                    options={durationPreferences}
                    classNames={{
                      control: (state) => "py-2",
                    }}
                    value={durationPreferences.find(
                      (option) => option.value === field.value,
                    )}
                    onChange={(selectedOption) => {
                      field.onChange(selectedOption?.value ?? null);
                    }}
                  />
                );
              }}
            />
          </div>
          <div className="mb-4">
            <Label htmlFor="email_input">
              Preferred Cities (Leave Blank for Any City)
            </Label>
            <AsyncSelect
              id="city_input"
              placeholder="Enter your preferred cities"
              classNames={{
                control: (state) => "py-2",
              }}
              loadOptions={cityOptions}
              value={watch("preferredCities")}
              isMulti={true}
              onChange={(
                selectedOptions: MultiValue<{
                  value: string;
                  label: string;
                }>,
              ) => {
                setValue("preferredCities", Array.from(selectedOptions));
              }}
            />
          </div>
        </>
      )}

      <div className="mb-5">
        <Button
          size="none"
          className="w-full text-lg py-4"
          type="submit"
          disabled={justSaved || isSubmitting}
        >
          {isSubmitting || isPending ? (
            <Loading className="w-6 h-6" />
          ) : justSaved ? (
            <div className="flex gap-2 items-center animate-fade-in">
              <Check className="w-6 h-6" /> Saved!
            </div>
          ) : (
            "Save"
          )}
        </Button>
      </div>
    </form>
  );
}
