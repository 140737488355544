import { useState } from "react";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { useMutation } from "@tanstack/react-query";
import { deleteAccount } from "@/ajax/account/deleteAccount";
import { supabase } from "@/ajax/clients/supabase";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import { AlertCircle } from "lucide-react";

export function DeleteAccount() {
  const [showDialog, setShowDialog] = useState(false);
  const [confirmText, setConfirmText] = useState("");
  const [error, setError] = useState("");

  const deleteAccountMutation = useMutation({
    mutationFn: deleteAccount,
    onSuccess: async () => {
      setShowDialog(false);
      const { error } = await supabase.auth.signOut();
      document.location.reload();
    },
    onError: (error) => {
      setError("Failed to delete account. Please try again.");
      console.error("Error:", error);
    },
  });

  const handleDelete = async () => {
    if (confirmText.toLowerCase() !== "delete my account") {
      setError("Please type 'delete my account' to confirm");
      return;
    }

    deleteAccountMutation.mutate({
      confirmation: confirmText,
    });
  };

  return (
    <div className="space-y-6">
      <Button
        variant="destructive"
        onClick={() => setShowDialog(true)}
        className="w-full sm:w-auto"
      >
        Delete Account
      </Button>

      <Dialog open={showDialog} onOpenChange={setShowDialog}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Are you absolutely sure?</DialogTitle>
            <DialogDescription>
              This action cannot be undone. This will permanently delete your
              account and remove your access to all services.
            </DialogDescription>
          </DialogHeader>

          <div>
            <Alert className="mt-4">
              <AlertCircle className="h-4 w-4" />
              <AlertTitle>Data Retention Notice</AlertTitle>
              <AlertDescription>
                While your account will be deleted, we are legally required to
                retain certain information:
                <ul className="list-disc pl-6 mt-2">
                  <li>Audit trails of your account activity</li>
                  <li>Transaction records and payment history</li>
                  <li>Other data required by regulatory compliance</li>
                </ul>
                This retained data will remain securely stored and only used for
                legal compliance purposes.
              </AlertDescription>
            </Alert>

            <div className="mt-4">
              <Label htmlFor="confirmDelete">
                Please type{" "}
                <span className="font-medium">delete my account</span> to
                confirm:
              </Label>
              <Input
                id="confirmDelete"
                value={confirmText}
                onChange={(e) => setConfirmText(e.target.value)}
                className="mt-2"
                placeholder="delete my account"
              />
              {error && <p className="text-sm text-red-500 mt-2">{error}</p>}
            </div>
          </div>

          <DialogFooter className="mt-4 flex-col sm:flex-row gap-2">
            <Button
              variant="outline"
              onClick={() => setShowDialog(false)}
              className="w-full sm:w-auto"
            >
              Cancel
            </Button>
            <Button
              variant="destructive"
              onClick={handleDelete}
              className="w-full sm:w-auto"
              disabled={deleteAccountMutation.isPending}
            >
              {deleteAccountMutation.isPending
                ? "Deleting..."
                : "Delete Account"}
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
}
