export type DocumentCategory = {
  name: string;
  fileTypeIds: number[];
  description: string;
  optional?: boolean;
};
export const DOCUMENT_CATEGORIES: DocumentCategory[] = [
  {
    name: "Board Certification Status and Certificates",
    fileTypeIds: [6],
    description:
      "Current board certification documents or proof of board eligibility",
  },
  {
    name: "CV with Relevant Work History",
    fileTypeIds: [1],
    description:
      "Current curriculum vitae detailing your education, training, and work experience",
  },
  {
    name: "Diploma",
    fileTypeIds: [21, 30],
    description:
      "Medical school diploma or equivalent educational certification",
  },
  {
    name: "Government Issued ID",
    fileTypeIds: [16, 35],
    description:
      "A valid government-issued photo ID such as a driver's license or passport",
  },
  {
    name: "Health Screening Documents",
    fileTypeIds: [5, 13, 20, 22, 23, 24, 31],
    description:
      "Recent health screening results including TB test, immunization records, and other required health documentation",
  },
  {
    name: "Malpractice Claims History",
    fileTypeIds: [29],
    description:
      "Documentation of any malpractice claims history or written statement if none exist",
  },

  {
    name: "Medical License(s)",
    fileTypeIds: [40],
    description:
      "Current state medical license(s) for all states where you practice",
  },

  {
    name: "Residency/Fellowship Certificate",
    fileTypeIds: [19],
    description:
      "Completion certificates from all residency and fellowship training programs",
  },
  {
    name: "ACLS/BLS Certification",
    fileTypeIds: [2, 3],
    description:
      "Current Advanced Cardiac Life Support (ACLS) and/or Basic Life Support (BLS) certification",
    optional: true,
  },
  {
    name: "DEA or CDS Certification",
    fileTypeIds: [17],
    description:
      "Current Drug Enforcement Administration (DEA) registration certificate or Controlled Dangerous Substances (CDS) certification",
  },
  {
    name: "ECFMG Certification (Required for International Medical Graduates)",
    fileTypeIds: [18],
    description:
      "Educational Commission for Foreign Medical Graduates (ECFMG) certification for international medical graduates",
    optional: true,
  },
  {
    name: "Malpractice Insurance",
    fileTypeIds: [28],
    description:
      "Current professional liability insurance certificate showing coverage amounts and dates",
    optional: true,
  },
  {
    name: "Other training diplomas or certificates",
    fileTypeIds: [7, 8, 10, 12, 21, 25, 26, 32, 33, 34, 36, 37, 38, 39],
    description:
      "Additional specialty training certificates, continuing education documentation, or other relevant certifications",
    optional: true,
  },
] as const;
