import {
    createRouter,
    ErrorComponent,
  } from "@tanstack/react-router";
  import "./index.css";
  
  // Import the generated route tree
  import { routeTree } from "./routeTree.gen";
  import { LoadingScreen } from "./components/LoadingScreen/LoadingScreen";

// Create a new router instance
export const router = createRouter({
    // defaultComponent: OfflineHandler,
    routeTree,
    defaultPendingComponent: (props) => {
      return (
        <div className="min-h-screen flex w-full items-center justify-center">
          <LoadingScreen />
        </div>
      );
    },
    defaultErrorComponent: ({ error }) => <ErrorComponent error={error} />,
    defaultPreload: "intent",
  });
  
  // Register the router instance for type safety
  declare module "@tanstack/react-router" {
    interface Register {
      router: typeof router;
    }
  }