import { zodResolver } from "@hookform/resolvers/zod";
import { useForm, useFieldArray } from "react-hook-form";
import * as z from "zod";
import { Form } from "@/components/ui/form";
import { AlertCircle, X, Plus } from "lucide-react";
import { Alert, AlertDescription } from "@/components/ui/alert";
import { useMutation } from "@tanstack/react-query";
import { saveCredentialsFields } from "@/ajax/credentials/saveCredentialsFields";
import { queryClient } from "@/ajax/queryClient";
import { useState } from "react";
import { SaveButton } from "@/components/Button/SaveButton";
import { Button } from "@/components/ui/button";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { EDUCATION_TYPE_MAP, EducationType, formSchema } from "./formConfigs";

type FormValues = z.infer<typeof formSchema>;

export function EducationForm({
  onSave,
  education,
}: {
  onSave: () => void;
  education: any;
}) {
  const [saveStatus, setSaveStatus] = useState<
    "idle" | "saving" | "saved" | "error"
  >("idle");
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [showTypeSelect, setShowTypeSelect] = useState(false);

  const mutation = useMutation({
    mutationFn: saveCredentialsFields,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["credentialsFields"] });
      setSaveStatus("idle");
      setErrorMessage(null);
      onSave();
    },
    onError: (error) => {
      setSaveStatus("error");
      setErrorMessage(
        error instanceof Error ? error.message : "Failed to save changes",
      );
      setTimeout(() => setSaveStatus("idle"), 4000);
    },
  });

  const educationValues = Object.entries(education).flatMap(([type, entries]) =>
    (entries as any[])?.map((entry) => {
      return EDUCATION_TYPE_MAP[type as EducationType].parseValue(entry.value)
    }) || []
  );

  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      education: educationValues,
    }
  });

  const {
    fields: educationFields,
    append: appendEducation,
    remove: removeEducation,
  } = useFieldArray({
    control: form.control,
    name: "education",
  });

  function onSubmit(data: FormValues) {
    setSaveStatus("saving");
    setErrorMessage(null);
    const existingTypes = new Set<EducationType>(
      data.education.map((e) => e.type as EducationType),
    );

    // Group education entries by type and track indices
    const educationByType: Record<
      EducationType,
      { entries: FormValues["education"][number][]; originalIndexes: number[] }
    > = data.education.reduce(
      (acc, edu) => {
        if (!acc[edu.type]) {
          // Get original indexes for this type from educationValues
          const originalValues = education[edu.type] || [];
          const originalIndexes = originalValues.map((v: any) => v.index || 1);
          acc[edu.type] = { entries: [], originalIndexes };
        }
        acc[edu.type].entries.push(edu);
        return acc;
      },
      {} as Record<
        EducationType,
        { entries: any[]; originalIndexes: number[] }
      >,
    );

    const fieldValues = [
      // Map existing education entries with their type-specific indices
      ...Object.entries(educationByType).flatMap(
        ([type, { entries, originalIndexes }]) => {
          const currentIndexes = new Set(entries.map((_, i) => i + 1));

          return [
            // Add current entries
            ...entries.map((edu, i) => {
              // Remove in_progress from the object
              const { in_progress, ...eduWithoutInProgress } = edu;
              return {
                field_code: type,
                value:
                  EDUCATION_TYPE_MAP[type as EducationType].formatValue(
                    eduWithoutInProgress,
                  ),
                index: i + 1,
                delete: false,
              };
            }),
            // Add delete entries for removed indexes
            ...originalIndexes
              .filter((index) => !currentIndexes.has(index))
              .map((index) => ({
                field_code: type,
                value: null,
                index,
                delete: true,
              })),
          ];
        },
      ),
      // Handle completely deleted education types
      ...Object.entries(educationValues)
        .filter(
          ([type, value]) => value && !existingTypes.has(type as EducationType),
        )
        .flatMap(([type]) => {
          const originalValues = education[type] || [];
          const originalIndexes = originalValues.map((v: any) => v.index || 1);
          return originalIndexes.map((index: number) => ({
            field_code: type,
            value: null,
            index,
            delete: true,
          }));
        }),
    ];

    mutation.mutate({ fieldValues });
  }

  const handleTypeSelect = (type: EducationType) => {
    appendEducation(EDUCATION_TYPE_MAP[type].createNew());
    setShowTypeSelect(false);
  };

  // Get current education types
  const currentTypes = new Set(educationFields.map((field) => field.type));

  return (
    <Form {...form}>
      {errorMessage && (
        <Alert variant="destructive" className="mb-6">
          <AlertCircle className="h-6 w-6" />
          <AlertDescription className="ml-2">{errorMessage}</AlertDescription>
        </Alert>
      )}

      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
        <div className="space-y-6">
          {educationFields.map((field, index) => {
            const EducationFields =
              EDUCATION_TYPE_MAP[field.type as EducationType]?.fields;
            return (
              <div key={field.id} className="border rounded-lg">
                <div className="p-4">
                  <div className="flex justify-between items-center">
                    <h3 className="text-lg font-semibold capitalize">
                      {EDUCATION_TYPE_MAP[field.type as EducationType]?.label}
                    </h3>
                    <Button
                      type="button"
                      variant="ghost"
                      size="sm"
                      onClick={() => removeEducation(index)}
                    >
                      <X className="h-4 w-4" />
                    </Button>
                  </div>
                  <EducationFields form={form} index={index} />
                </div>
              </div>
            );
          })}

          {showTypeSelect && (
            <div className="space-y-4">
              <Select onValueChange={handleTypeSelect}>
                <SelectTrigger>
                  <SelectValue placeholder="Select education type" />
                </SelectTrigger>
                <SelectContent>
                  {Object.entries(EDUCATION_TYPE_MAP).map(([type, config]) => (
                    <SelectItem key={type} value={type}>
                      {config.selectLabel}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>
          )}

          {!showTypeSelect &&
            currentTypes.size < Object.keys(EDUCATION_TYPE_MAP).length && (
              <Button
                type="button"
                variant="outline"
                onClick={() => setShowTypeSelect(true)}
                className="flex items-center gap-2"
              >
                <Plus className="h-4 w-4" />
                Add Education
              </Button>
            )}
        </div>

        <SaveButton saveStatus={saveStatus} />
        <Button
          className="w-full p-6"
          type="button"
          variant="outline"
          onClick={() => onSave()}
        >
          Cancel
        </Button>
      </form>
    </Form>
  );
}
