import { format } from "date-fns";
import { z } from "zod";
import { ProfessionalEducationFields } from "./ProfessionalEducationFields";
import { ProfessionalTrainingFields } from "./ProfessionalTrainingFields";
import { FifthPathwayEducationFields } from "./FifthPathwayEducationFields";
import { UndergraduateEducationFields } from "./UndergraduateEducationFields";

export type EducationType =
  | "professional_education"
  | "professional_training"
  | "fifth_pathway_education"
  | "undergraduate_education";

export const EDUCATION_TYPE_MAP: {
  [key in EducationType]: {
    label: string;
    schema: z.ZodObject<any, any, any, any>;
    createNew: () => any;
    formatValue: (edu: any) => any;
    parseValue: (value: any) => any;
    selectLabel: string;
    fields: React.ComponentType<any>;
  };
} = {
  professional_education: {
    label: "Professional Education",
    schema: z.object({
      type: z.literal("professional_education"),
      start_date: z.date(),
      end_date: z.date(),
      degree: z.string().min(1, "Degree is required").max(1000),
      program: z.union([z.string().max(1000), z.null()]).optional(),
      institution: z.string().min(1, "Institution is required").max(1000),
      city: z.union([z.string().max(1000), z.null()]).optional(),
      state: z.union([z.string().max(1000), z.null()]).optional(),
      country: z.union([z.string().max(1000), z.null()]).optional(),
      specialty: z.union([z.string().max(1000), z.null()]).optional(),
      delete: z.boolean().optional(),
    }),
    createNew: () => ({
      type: "professional_education" as const,
      start_date: new Date(),
      end_date: new Date(),
      degree: "",
      program: "",
      institution: "",
      location: "",
      specialty: "",
    }),
    formatValue: (edu: any) => ({
      start_date: format(edu.start_date, "yyyy-MM-dd"),
      end_date: format(edu.end_date, "yyyy-MM-dd"),
      degree: edu.degree,
      program: edu.program,
      institution: edu.institution,
      location: edu.location,
      specialty: edu.specialty,
    }),
    parseValue: (value: any) => ({
      type: "professional_education" as const,
      start_date: new Date(value.start_date),
      end_date: new Date(value.end_date),
      degree: value.degree,
      program: value.program,
      institution: value.institution,
      location: value.location,
      specialty: value.specialty,
    }),
    selectLabel: "Professional Education",
    fields: ProfessionalEducationFields,
  },
  professional_training: {
    label: "Professional Training",
    schema: z.object({
      type: z.literal("professional_training"),
      training_type: z.enum(["Residency", "Fellowship", "Internship", "Other"]),
      start_date: z.date({
        required_error: "Start date is required",
      }),
      end_date: z.date().nullable(),
      in_progress: z.boolean().default(false),
      institution: z.string().min(1, "Institution is required").max(1000),
      affiliated_university: z
        .union([z.string().max(1000), z.null()])
        .optional(),
      country: z
        .union([z.string().min(1, "Country is required").max(100), z.null()])
        .optional(),
      state: z.union([z.string().max(100), z.null()]).optional(),
      city: z
        .union([z.string().min(1, "City is required").max(100), z.null()])
        .optional(),
      degree: z.union([z.string().max(1000), z.null()]).optional(),
      program: z.union([z.string().max(1000), z.null()]).optional(),
      specialty: z.union([z.string().max(1000), z.null()]).optional(),
      delete: z.boolean().optional(),
    }),
    createNew: () => ({
      type: "professional_training" as const,
      training_type: "Residency" as const,
      start_date: new Date(),
      end_date: new Date(),
      in_progress: false,
      institution: "",
      affiliated_university: "",
      country: "",
      state: "",
      city: "",
      degree: "",
      program: "",
      specialty: "",
    }),
    formatValue: (edu: any) => ({
      training_type: edu.training_type,
      start_date: format(edu.start_date, "yyyy-MM-dd"),
      end_date: edu.end_date ? format(edu.end_date, "yyyy-MM-dd") : null,
      in_progress: edu.in_progress,
      institution: edu.institution,
      affiliated_university: edu.affiliated_university,
      country: edu.country,
      state: edu.state,
      city: edu.city,
      degree: edu.degree,
      program: edu.program,
      specialty: edu.specialty,
    }),
    parseValue: (value: any) => ({
      type: "professional_training" as const,
      training_type: value.training_type,
      start_date: new Date(value.start_date),
      end_date: value.end_date ? new Date(value.end_date) : null,
      in_progress: value.in_progress,
      institution: value.institution,
      affiliated_university: value.affiliated_university,
      country: value.country,
      state: value.state,
      city: value.city,
      degree: value.degree,
      program: value.program,
      specialty: value.specialty,
    }),
    selectLabel: "Professional Training",
    fields: ProfessionalTrainingFields,
  },
  fifth_pathway_education: {
    label: "Fifth Pathway Education",
    schema: z.object({
      type: z.literal("fifth_pathway_education"),
      start_date: z.date({
        required_error: "Start date is required",
      }),
      end_date: z.date().nullable(),
      in_progress: z.boolean().default(false),
      degree: z.string().min(1, "Degree is required").max(1000),
      program: z.string().max(1000).optional(),
      institution: z.string().min(1, "Institution is required").max(1000),
      country: z.string().min(1, "Country is required").max(100),
      state: z.string().min(1, "State is required").max(100),
      city: z.string().min(1, "City is required").max(100),
      specialty: z.string().max(1000).optional(),
      delete: z.boolean().optional(),
    }),
    createNew: () => ({
      type: "fifth_pathway_education" as const,
      start_date: new Date(),
      end_date: new Date(),
      in_progress: false,
      degree: "",
      program: "",
      institution: "",
      country: "",
      state: "",
      city: "",
      specialty: "",
    }),
    formatValue: (edu: any) => ({
      start_date: format(edu.start_date, "yyyy-MM-dd"),
      end_date: edu.end_date ? format(edu.end_date, "yyyy-MM-dd") : null,
      in_progress: edu.in_progress,
      degree: edu.degree,
      program: edu.program,
      institution: edu.institution,
      country: edu.country,
      state: edu.state,
      city: edu.city,
      specialty: edu.specialty,
    }),
    parseValue: (value: any) => ({
      type: "fifth_pathway_education" as const,
      start_date: new Date(value.start_date),
      end_date: value.end_date ? new Date(value.end_date) : null,
      in_progress: value.in_progress,
      degree: value.degree,
      program: value.program,
      institution: value.institution,
      country: value.country,
      state: value.state,
      city: value.city,
      specialty: value.specialty,
    }),
    selectLabel: "Fifth Pathway Education",
    fields: FifthPathwayEducationFields,
  },
  undergraduate_education: {
    label: "Undergraduate Education",
    schema: z.object({
      type: z.literal("undergraduate_education"),
      start_date: z.date({
        required_error: "Start date is required",
      }),
      end_date: z.date().nullable(),
      in_progress: z.boolean().default(false),
      degree: z.string().min(1, "Degree is required").max(1000),
      program: z.union([z.string().max(1000), z.null()]).optional(),
      institution: z.string().min(1, "Institution is required").max(1000),
      country: z
        .union([z.string().min(1, "Country is required").max(100), z.null()])
        .optional(),
      state: z.union([z.string().max(100), z.null()]).optional(),
      city: z
        .union([z.string().min(1, "City is required").max(100), z.null()])
        .optional(),
      delete: z.boolean().optional(),
    }),
    createNew: () => ({
      type: "undergraduate_education" as const,
      start_date: new Date(),
      end_date: new Date(),
      in_progress: false,
      degree: "",
      program: "",
      institution: "",
      country: "",
      state: "",
      city: "",
    }),
    formatValue: (edu: any) => ({
      start_date: format(edu.start_date, "yyyy-MM-dd"),
      end_date: edu.end_date ? format(edu.end_date, "yyyy-MM-dd") : null,
      in_progress: edu.in_progress,
      degree: edu.degree,
      program: edu.program,
      institution: edu.institution,
      country: edu.country,
      state: edu.state,
      city: edu.city,
    }),
    parseValue: (value: any) => ({
      type: "undergraduate_education" as const,
      start_date: new Date(value.start_date),
      end_date: value.end_date ? new Date(value.end_date) : null,
      in_progress: value.in_progress,
      degree: value.degree,
      program: value.program,
      institution: value.institution,
      country: value.country,
      state: value.state,
      city: value.city,
    }),
    selectLabel: "Undergraduate Education",
    fields: UndergraduateEducationFields,
  },
};

export const formSchema = z.object({
  education: z.array(
    z.discriminatedUnion("type", [
      EDUCATION_TYPE_MAP.professional_education.schema,
      EDUCATION_TYPE_MAP.professional_training.schema,
      EDUCATION_TYPE_MAP.fifth_pathway_education.schema,
      EDUCATION_TYPE_MAP.undergraduate_education.schema,
    ]),
  ),
});
