import { Card } from "@/components/ui/card";
import { createFileRoute } from "@tanstack/react-router";
import { Building2 } from "lucide-react";

export const Route = createFileRoute("/_provider/provider/work-history/")({
  component: WorkHistoryPage,
});

function WorkHistoryPage() {
  return (
    <div className="min-h-screen bg-gray-100 p-2 md:p-8 pt-6">
      <div className="max-w-6xl mx-auto">
        <h1 className="text-2xl font-bold mb-6 px-4">
          Credentialed Facilities
        </h1>
        <div className="px-4">
          <Card className="py-12">
            <div className="flex flex-col items-center justify-center text-center">
              <Building2 className="w-12 h-12 text-gray-400 mb-4" />
              <h3 className="text-lg font-medium text-gray-900 mb-2">
                No facilities yet
              </h3>
              <p className="text-sm text-gray-500 max-w-sm">
                Facilities you are credentialed at will show up here after your
                first shift
              </p>
            </div>
          </Card>
        </div>
      </div>
    </div>
  );
}
