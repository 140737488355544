import {
  createFileRoute,
  Outlet,
  redirect,
  ScrollRestoration,
} from "@tanstack/react-router";
import { ProviderLayout } from "@/components/Layouts/ProviderLayout";
import { useEffect } from "react";
import { supabase } from "@/ajax/clients/supabase";
import { queryClient } from "@/ajax/queryClient";
import { providerUserQueryOptions } from "../ajax/queries";
import { getOnboardingSteps } from "@/features/onboarding/utils/getOnboardingSteps";
import OfflineHandler from "@/components/OfflineHandler";

export const Route = createFileRoute("/_provider")({
  beforeLoad: async ({ location, context }) => {
    let providerUser = null;
    try {
      providerUser = await queryClient.fetchQuery(providerUserQueryOptions());
    } catch (e) {
      console.log("error fetching provider user", e);

      if (e instanceof Error && e.message.includes("NetworkError")) {
        return {
          user: context.user,
          providerUser: null,
          isOnboarding: false,
        };
      }
    }

    if (!context.user || !providerUser) {
      throw redirect({
        to: "/auth-provider/signin",
        search: {
          // Use the current location to power a redirect after login
          // (Do not use `router.state.resolvedLocation` as it can
          // potentially lag behind the actual current location)
          redirect: location.pathname,
        },
      });
    }

    const isOnboarding = getOnboardingSteps(providerUser).length > 0;

    // Redirect to the onboarding if it's not completed
    if (isOnboarding && location.pathname.startsWith("/provider/")) {
      throw redirect({
        to: "/provider-onboarding",
      });
    }

    return {
      user: context.user,
      providerUser,
      isOnboarding,
    };
  },
  component: () => {
    const { user, isOnboarding } = Route.useRouteContext();

    useEffect(() => {
      const {
        data: { subscription },
      } = supabase.auth.onAuthStateChange((event, session) => {
        if (event === "SIGNED_OUT") {
          document.location.reload();
        }
      });
      return () => {
        subscription.unsubscribe();
      };
    }, []);

    if (!user) {
      return <OfflineHandler />;
    }

    return (
      <ProviderLayout showSidebar={!isOnboarding}>
        <Outlet />
        <ScrollRestoration />
      </ProviderLayout>
    );
  },
});
