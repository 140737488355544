import { callSupabaseFnFromClient } from "../utils/callSupabaseFnFromClient";
export type SharedUserType = {
  id: string;
  email: string;
  phone: string;
  profile_image_path: string;
};

export const getSharedUser = async (
  userId: string,
): Promise<SharedUserType> => {
  return await callSupabaseFnFromClient<SharedUserType>({
    fnName: "get_shared_user",
    args: {
      user_id: userId,
    },
  });
};
