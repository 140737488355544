import { apiRequestPostWithRetry } from "../utils/apiRequest";

type UpdateProfileImageResponse = {
  success: boolean;
};
export const uploadProfileImage =
  async (): Promise<UpdateProfileImageResponse> => {
    const response = await apiRequestPostWithRetry({
      path: "/profile/image",
      body: {},
    });
    return response.data;
  };
