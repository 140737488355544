import { zodResolver } from "@hookform/resolvers/zod";
import { useFieldArray, useForm } from "react-hook-form";
import * as z from "zod";
import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { format } from "date-fns";
import { CalendarIcon, X, AlertCircle } from "lucide-react";
import { Alert, AlertDescription } from "@/components/ui/alert";
import { useMutation } from "@tanstack/react-query";
import { saveCredentialsFields } from "@/ajax/credentials/saveCredentialsFields";
import { queryClient } from "@/ajax/queryClient";
import { useState } from "react";
import DatePicker from "react-datepicker";
import { SaveButton } from "@/components/Button/SaveButton";
import { SpecialtiesSelect } from "@/components/Selects/SpecialtiesSelect";

const specialtySchema = z.object({
  specialty_code: z.string().max(255),
  certifying_board: z.string().max(255).optional().nullable(),
  state: z.string().max(2).optional().nullable(),
  certification_number: z.string().max(255).optional().nullable(),
  initial_certification_date: z.date().optional().nullable(),
  expiration_date: z.date().optional().nullable(),
});

const formSchema = z.object({
  specialties: z.array(specialtySchema),
});

type FormValues = z.infer<typeof formSchema>;

export function SpecialtiesForm({
  onSave,
  specialties,
}: {
  onSave: () => void;
  specialties: any;
}) {
  const [saveStatus, setSaveStatus] = useState<
    "idle" | "saving" | "saved" | "error"
  >("idle");
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const mutation = useMutation({
    mutationFn: saveCredentialsFields,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["credentialsFields"] });
      setSaveStatus("idle");
      setErrorMessage(null);
      onSave();
    },
    onError: (error) => {
      setSaveStatus("error");
      setErrorMessage(
        error instanceof Error ? error.message : "Failed to save changes",
      );
      setTimeout(() => setSaveStatus("idle"), 4000);
    },
  });

  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      specialties:
        specialties.map(
          (val: {
            specialty_code: string;
            certifying_board: string;
            state: string;
            certification_number: string;
            initial_certification_date: string;
            expiration_date: string;
          }) => ({
            specialty_code: val.specialty_code || "",
            certifying_board: val.certifying_board || "",
            state: val.state || "",
            certification_number: val.certification_number || "",
            initial_certification_date: val.initial_certification_date
              ? new Date(val.initial_certification_date)
              : null,
            expiration_date: val.expiration_date
              ? new Date(val.expiration_date)
              : null,
          }),
        ) || [],
    },
  });

  const {
    fields: specialtyFields,
    append: appendSpecialty,
    remove: removeSpecialty,
  } = useFieldArray({
    control: form.control,
    name: "specialties",
  });

  function onSubmit(data: FormValues) {
    setSaveStatus("saving");
    setErrorMessage(null);

    const existingValues = specialties || [];
    const newIndexMax = data.specialties.length;

    const fieldValues = [
      ...data.specialties.map((specialty, index) => ({
        field_code: "specialty",
        value: {
          specialty_code: specialty.specialty_code,
          certifying_board: specialty.certifying_board,
          state: specialty.state,
          certification_number: specialty.certification_number,
          initial_certification_date: specialty.initial_certification_date
            ? format(specialty.initial_certification_date, "yyyy-MM-dd")
            : null,
          expiration_date: specialty.expiration_date
            ? format(specialty.expiration_date, "yyyy-MM-dd")
            : null,
        },
        index: index + 1,
        delete: false,
      })),

      ...existingValues
        .filter((val: { index: number }) => val.index > newIndexMax)
        .map((val: { index: number }) => ({
          field_code: "specialty",
          value: null,
          delete: true,
          index: val.index,
        })),
    ];

    mutation.mutate({
      fieldValues,
    });
  }

  const renderSpecialtyFields = (fields: any[], remove: any) => {
    return fields.map((field, index) => (
      <div key={field.id} className="space-y-4 p-4 border rounded-lg relative">
        <Button
          type="button"
          variant="ghost"
          size="icon"
          className="absolute right-2 top-2"
          onClick={() => {
            remove(index as number);
          }}
        >
          <X className="h-4 w-4" />
        </Button>

        <FormField
          control={form.control}
          name={`specialties.${index}.specialty_code`}
          render={({ field }) => (
            <FormItem>
              <FormLabel>Specialty Code</FormLabel>
              <FormControl>
                <SpecialtiesSelect {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name={`specialties.${index}.certifying_board`}
          render={({ field }) => (
            <FormItem>
              <FormLabel>Certifying Board</FormLabel>
              <FormControl>
                <Input {...field} value={field.value || ""} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name={`specialties.${index}.certification_number`}
          render={({ field }) => (
            <FormItem>
              <FormLabel>Certification Number</FormLabel>
              <FormControl>
                <Input {...field} value={field.value || ""} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name={`specialties.${index}.expiration_date`}
          render={({ field }) => (
            <FormItem>
              <FormLabel>Expiration Date</FormLabel>
              <div className="relative">
                <DatePicker
                  selected={field.value}
                  onChange={(date) => field.onChange(date)}
                  customInput={
                    <Input
                      value={
                        field.value ? format(field.value, "MM-dd-yyyy") : ""
                      }
                    />
                  }
                  dateFormat="MM-dd-yyyy"
                  wrapperClassName="w-full"
                />
                <CalendarIcon className="absolute right-3 top-1/2 -translate-y-1/2 h-4 w-4 opacity-50 pointer-events-none" />
              </div>
              <FormMessage />
            </FormItem>
          )}
        />
      </div>
    ));
  };

  return (
    <Form {...form}>
      {errorMessage && (
        <Alert variant="destructive" className="mb-6">
          <AlertCircle className="h-6 w-6" />
          <AlertDescription className="ml-2">{errorMessage}</AlertDescription>
        </Alert>
      )}

      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
        <div className="space-y-6">
          <div>
            <div className="space-y-4">
              {renderSpecialtyFields(specialtyFields, removeSpecialty)}
              <Button
                type="button"
                variant="outline"
                size="sm"
                onClick={() =>
                  appendSpecialty({
                    specialty_code: "",
                    certifying_board: "",
                    state: "",
                    certification_number: "",
                    initial_certification_date: null,
                    expiration_date: null,
                  })
                }
              >
                Add Specialty
              </Button>
            </div>
          </div>
        </div>

        <SaveButton saveStatus={saveStatus} />
        <Button onClick={onSave} className="w-full p-6" variant="outline">
          Cancel
        </Button>
      </form>
    </Form>
  );
}
