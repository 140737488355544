import { ProviderUser } from "@/common/types/ProviderUser";
import { supabase } from "@/ajax/clients/supabase";

export async function getProviderUser(): Promise<ProviderUser> {
  const { data, error } = await supabase.rpc("get_provider_user");

  if (error) {
    throw error;
  }

  return data as ProviderUser;
}
