import { WorkHistoryForm } from "@/features/profile/components/WorkHistoryForm";
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader } from "@/components/ui/card";
import { cn } from "@/lib/utils";
import { format } from "date-fns";
import { Building2, Pencil, Info } from "lucide-react";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";

export function WorkHistoryCard({
  editable,
  isEditing,
  onEditChange,
  workExperiences,
}: {
  editable: boolean;
  isEditing: boolean;
  onEditChange: (editing: boolean) => void;
  workExperiences: any[];
}) {
  return (
    <Card
      className={cn("mt-4", {
        "shadow-none border-none": isEditing,
      })}
    >
      <CardHeader className="pb-1">
        <div className="flex items-center justify-between">
          <h3 className="text-lg font-semibold">Work History</h3>
          {isEditing || !editable ? null : (
            <Button
              variant="ghost"
              size="icon"
              onClick={() => onEditChange(true)}
            >
              <Pencil className="h-4 w-4" />
            </Button>
          )}
        </div>
      </CardHeader>
      <CardContent className="space-y-2">
        {isEditing ? (
          <WorkHistoryForm
            workHistory={workExperiences}
            onSave={() => onEditChange(false)}
          />
        ) : (
          <>
            {workExperiences.map((exp: any, index: number) => (
              <div key={index} className="space-y-2">
                <div className="flex items-center gap-3">
                  <Building2 className="h-5 w-5 text-gray-500" />
                  <div>
                    <div className="flex items-center gap-2">
                      <span className="font-semibold">
                        {exp.value.position}
                      </span>
                      {exp.value.responsibilities && (
                        <Popover>
                          <PopoverTrigger asChild>
                            <Button
                              variant="ghost"
                              size="icon"
                              className="h-6 w-6"
                            >
                              <Info className="h-4 w-4" />
                            </Button>
                          </PopoverTrigger>
                          <PopoverContent className="w-80">
                            <div className="font-semibold mb-2">
                              Responsibilities
                            </div>
                            <div className="text-sm text-gray-600">
                              {exp.value.responsibilities}
                            </div>
                          </PopoverContent>
                        </Popover>
                      )}
                    </div>
                    <div className="text-sm text-gray-600">
                      {exp.value.organization}
                    </div>
                    <div className="text-sm text-gray-500">
                      {format(new Date(exp.value.start_date), "MMM yyyy")} -
                      {exp.value.end_date
                        ? format(new Date(exp.value.end_date), " MMM yyyy")
                        : " Present"}
                    </div>
                    <div className="text-sm text-gray-500">
                      {exp.value.location}
                    </div>
                  </div>
                </div>
              </div>
            ))}
            {workExperiences.length === 0 && (
              <div className="text-center text-gray-500">
                No work experience added yet.
              </div>
            )}
          </>
        )}
      </CardContent>
    </Card>
  );
}
