import { ProviderUser } from "@/common/types/ProviderUser";
import { supabase } from "../clients/supabase";
const UPLOAD_BUCKET_NAME = "profile_image";

export const getProfileImage = async (impagePath: string | null | undefined) => {
  if (!impagePath) {
    return null;
  }
  const { data, error } = await supabase.storage
    .from(UPLOAD_BUCKET_NAME)
    .createSignedUrl(impagePath, 60 * 60 * 24);
  if (error) {
    console.error(error);
    return null;
  }
  return data.signedUrl;
};
