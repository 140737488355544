import { getFileData } from "@/ajax/documents/getFileData";
import { Filesystem, Directory } from '@capacitor/filesystem';
import { FileOpener, FileOpenerOptions } from '@capacitor-community/file-opener';

import { Capacitor } from '@capacitor/core';

export const downloadFile = async ({
  userId,
  fileId,
  fileName,
}: {
  userId: string;
  fileId: string;
  fileName: string;
}) => {
  const data = await getFileData({
    userId,
    fileId,
  });

  if (Capacitor.isNativePlatform()) {
    // Convert Blob to base64 string
    const base64Data = await new Promise<string>((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(data);
      reader.onloadend = () => {
        const base64String = reader.result as string;
        // Remove the data URL prefix (e.g., "data:application/octet-stream;base64,")
        resolve(base64String.split(',')[1]);
      };
    });
    await Filesystem.requestPermissions();

    // For iOS, write to Documents directory which is visible in Files app
    const result = await Filesystem.writeFile({
      path: fileName,
      data: base64Data,
      directory: Directory.Documents,
      recursive: true
    });
    const fileOpenerOptions: FileOpenerOptions = {
      filePath: result.uri,
      openWithDefault: true,
    };
    await FileOpener.open(fileOpenerOptions);
  } else {
    // For web, use standard download approach
    const blob = new Blob([data], { type: "application/octet-stream" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.style.display = "none";
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  }

  return true;
};
