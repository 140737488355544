import { callSupabaseFnFromClient, callSupabaseFnFromClientNoThrow } from "../utils/callSupabaseFnFromClient";
import { Json } from "../../common/types/database.types";
import { PostgrestError } from "@supabase/supabase-js";
export type FileType = {
  id: string;
  batch_id: string;
  file_name: string;
  generated_title: string | null;
  type_id: number;
  type_name: string;
  type_category: string;
  data: Json;
  created_at: string;
  renewals: {
    id: string;
    title: string;
    expires_at: string;
    issued_at: string;
  }[];
  expires_at: string;
  renew_url: string;
};

export const getFilesCheckPermission = async ({
  id,
  switchUserId,
}: {
  id?: string | undefined;
  switchUserId?: string | undefined;
}): Promise<{data: FileType[] | null, hasPermission: boolean}> => {
  const {data, error} = await callSupabaseFnFromClientNoThrow<FileType[]>({
    fnName: "get_user_credentials_files",
    args: {
      filter_id: id,
      switch_user_id: switchUserId,
    },
  });

  if (error) {
    if (error.code === "A0403") {
      return {data: null, hasPermission: false};
    }
    throw error;
  }
  return {data, hasPermission: true};
};

export const getFiles = async ({
  id,
  switchUserId,
}: {
  id?: string | undefined;
  switchUserId?: string | undefined;
}): Promise<FileType[]> => {
  const {data, hasPermission} = await getFilesCheckPermission({id, switchUserId});
  if (!hasPermission) {
    throw new Error("No permission to get files");
  }
  if (!data) {
    throw new Error("No data returned from getFilesCheckPermission");
  }
  return data;
};


