import {
  callSupabaseFnFromClient,
  callSupabaseRpc,
} from "@/ajax/utils/callSupabaseFnFromClient";

export type Specialty = {
  id: string;
  code: string;
  grouping: string;
  classification: string;
  name: string;
  is_parent: boolean;
};
export const getSpecialties = async () => {
  const { error, data } = await callSupabaseRpc({
    fnName: "get_medical_specialties",
  }).returns<Specialty[]>();

  if (error) {
    console.error("Error:", error);
    throw error;
  }

  return data;
};
