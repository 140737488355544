import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader } from "@/components/ui/card";
import { cn } from "@/lib/utils";
import { formatDate } from "date-fns";
import { IdCard, Pencil } from "lucide-react";
import { StateLicensesForm } from "./StateLicensesForm";

export function StateLicensesCard({
  editable,
  isEditing,
  onEditChange,
  stateLicenses,
}: {
  editable: boolean;
  isEditing: boolean;
  onEditChange: (editing: boolean) => void;
  stateLicenses: any[];
}) {
  return (
    <Card
      className={cn("mt-4", {
        "shadow-none border-none": isEditing,
      })}
    >
      <CardHeader className="pb-1">
        <div className="flex items-center justify-between">
          <h3 className="text-lg font-semibold">State Licenses</h3>
          {isEditing || !editable ? null : (
            <Button
              variant="ghost"
              size="icon"
              onClick={() => onEditChange(true)}
            >
              <Pencil className="h-4 w-4" />
            </Button>
          )}
        </div>
      </CardHeader>
      <CardContent className="space-y-2">
        {isEditing ? (
          <StateLicensesForm
            stateLicenses={stateLicenses}
            onSave={() => onEditChange(false)}
          />
        ) : (
          <>
            {stateLicenses.map((license: any, index: number) => (
              <div key={index} className="flex items-center justify-between">
                <div className="flex items-center gap-3">
                  <IdCard className="h-5 w-5 text-gray-500" />
                  <div>
                    <div className="font-semibold">
                      {license.value.state} Medical License
                    </div>
                    <div className="text-sm text-gray-600">
                      License #: {license.value.license_number}
                    </div>
                    <div className="text-sm text-gray-500">
                      Exp.{" "}
                      {formatDate(license.value.expiration_date, "MMM yyyy")}
                    </div>
                  </div>
                </div>
              </div>
            ))}
            {stateLicenses.length === 0 && (
              <div className="text-center text-gray-500">
                No state licenses added yet.
              </div>
            )}
          </>
        )}
      </CardContent>
    </Card>
  );
}
