"use client";

import { useState } from "react";
import { Plus, MoreVertical, Pencil, Trash2 } from "lucide-react";

import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import * as React from "react";
import { createFileRoute } from "@tanstack/react-router";

interface Item {
  name: string;
  count: number;
  category: string;
}
export const Route = createFileRoute("/_provider/provider/procedure-log/")({
  component: ProcedureLogPage,
});

function ProcedureLogPage() {
  const [items, setItems] = useState<Item[]>([]);
  const [categories, setCategories] = useState<string[]>(["Uncategorized"]);
  const [newItemName, setNewItemName] = useState("");
  const [newCategory, setNewCategory] = useState("");
  const [activeCategory, setActiveCategory] = useState("Uncategorized");
  const [itemToDelete, setItemToDelete] = useState<number | null>(null);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isCategoryDialogOpen, setIsCategoryDialogOpen] = useState(false);
  const [isEditCategoriesDialogOpen, setIsEditCategoriesDialogOpen] =
    useState(false);
  const [editingItem, setEditingItem] = useState<number | null>(null);
  const [editItemName, setEditItemName] = useState("");
  const [editItemCount, setEditItemCount] = useState<number>(0);
  const [editedCategories, setEditedCategories] = useState<string[]>([]);

  const addItem = () => {
    if (newItemName.trim()) {
      setItems([
        ...items,
        { name: newItemName.trim(), count: 0, category: activeCategory },
      ]);
      setNewItemName("");
    }
  };

  const addCategory = () => {
    if (newCategory.trim() && !categories.includes(newCategory.trim())) {
      const newCategoryName = newCategory.trim();
      setCategories([...categories, newCategoryName]);
      setActiveCategory(newCategoryName);
      setNewCategory("");
      setIsCategoryDialogOpen(false);
    }
  };

  const updateCount = (index: number, increment: number) => {
    const newItems = [...items];
    newItems[index].count = Math.max(0, newItems[index].count + increment);
    setItems(newItems);
  };

  const removeItem = () => {
    if (itemToDelete !== null) {
      setItems(items.filter((_, i) => i !== itemToDelete));
      setItemToDelete(null);
      setIsDeleteDialogOpen(false);
    }
  };

  const startEditingItem = (index: number) => {
    setEditingItem(index);
    setEditItemName(items[index].name);
    setEditItemCount(items[index].count);
  };

  const saveEditedItem = () => {
    if (editingItem !== null && editItemName.trim()) {
      const newItems = [...items];
      newItems[editingItem].name = editItemName.trim();
      newItems[editingItem].count = editItemCount;
      setItems(newItems);
      setEditingItem(null);
    }
  };

  const handleCategoryChange = (value: string) => {
    if (value === "new-category") {
      setIsCategoryDialogOpen(true);
    } else {
      setActiveCategory(value);
    }
  };

  const openEditCategoriesDialog = () => {
    setEditedCategories([...categories]);
    setIsEditCategoriesDialogOpen(true);
  };

  const updateEditedCategory = (index: number, newName: string) => {
    const newEditedCategories = [...editedCategories];
    newEditedCategories[index] = newName;
    setEditedCategories(newEditedCategories);
  };

  const deleteCategory = (index: number) => {
    if (editedCategories[index] !== "Uncategorized") {
      const newEditedCategories = editedCategories.filter(
        (_, i) => i !== index,
      );
      setEditedCategories(newEditedCategories);
    }
  };

  const saveEditedCategories = () => {
    setCategories(editedCategories);

    // Update items with new category names
    const categoryMap = categories.reduce(
      (acc, oldCat, index) => {
        acc[oldCat] = editedCategories[index];
        return acc;
      },
      {} as { [key: string]: string },
    );

    const updatedItems = items.map((item) => ({
      ...item,
      category: categoryMap[item.category] || "Uncategorized",
    }));

    setItems(updatedItems);
    setActiveCategory(categoryMap[activeCategory] || "Uncategorized");
    setIsEditCategoriesDialogOpen(false);
  };

  return (
    <div className="w-full p-2 pt-4 md:pt-8 md:p-8 min-h-screen bg-gray-100">
      <div className="max-w-4xl mx-auto">
        <div className="flex justify-between items-center mb-8">
          <h2 className="text-title-md2 font-bold text-black dark:text-white">
            Procedure Log
          </h2>
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="ghost" size="icon">
                <MoreVertical className="h-5 w-5" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              <DropdownMenuItem onClick={openEditCategoriesDialog}>
                Edit Categories
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>

        <div className="bg-white rounded-lg p-6 w-full">
          <div className="flex flex-col sm:flex-row mb-4 space-y-2 sm:space-y-0 sm:space-x-2">
            <Select value={activeCategory} onValueChange={handleCategoryChange}>
              <SelectTrigger className="w-full sm:w-[180px]">
                <SelectValue placeholder="Select a category" />
              </SelectTrigger>
              <SelectContent>
                {categories.map((category) => (
                  <SelectItem key={category} value={category}>
                    {category}
                  </SelectItem>
                ))}
                <SelectItem value="new-category">+ Add New Category</SelectItem>
              </SelectContent>
            </Select>
            <Input
              type="text"
              value={newItemName}
              onChange={(e) => setNewItemName(e.target.value)}
              placeholder="Enter item name"
              className="flex-grow"
              onKeyPress={(e) => e.key === "Enter" && addItem()}
            />
            <Button
              variant="outline"
              onClick={addItem}
              className="w-full sm:w-auto"
            >
              Add Item
            </Button>
          </div>
          <div className="space-y-4">
            {items
              .filter((item) => item.category === activeCategory)
              .map((item, index) => (
                <div
                  key={index}
                  className="flex flex-col sm:flex-row items-center justify-between border p-4 rounded-lg space-y-2 sm:space-y-0"
                >
                  {editingItem === index ? (
                    <Input
                      type="text"
                      value={editItemName}
                      onChange={(e) => setEditItemName(e.target.value)}
                      onBlur={saveEditedItem}
                      onKeyPress={(e) => e.key === "Enter" && saveEditedItem()}
                      className="w-full sm:w-1/3 mb-2 sm:mb-0"
                      autoFocus
                    />
                  ) : (
                    <span className="font-medium text-center sm:text-left w-full sm:w-1/3 mb-2 sm:mb-0">
                      {item.name}
                    </span>
                  )}
                  <div className="flex items-center justify-center sm:justify-end space-x-4 w-full sm:w-2/3">
                    <Button
                      size="none"
                      variant="outline"
                      onClick={() => updateCount(items.indexOf(item), 1)}
                      aria-label={`Increase count for ${item.name}`}
                      className="h-12 w-12"
                    >
                      <Plus className="h-6 w-6" />
                    </Button>
                    {editingItem === index ? (
                      <Input
                        type="number"
                        value={editItemCount}
                        onChange={(e) =>
                          setEditItemCount(
                            Math.max(0, parseInt(e.target.value) || 0),
                          )
                        }
                        onBlur={saveEditedItem}
                        onKeyPress={(e) =>
                          e.key === "Enter" && saveEditedItem()
                        }
                        className="w-20 text-center"
                        min="0"
                      />
                    ) : (
                      <span className="w-16 text-center text-xl font-semibold">
                        {item.count}
                      </span>
                    )}
                    <DropdownMenu>
                      <DropdownMenuTrigger asChild>
                        <Button
                          variant="ghost"
                          className="h-12 w-12"
                          aria-label="More options"
                        >
                          <MoreVertical className="h-6 w-6" />
                        </Button>
                      </DropdownMenuTrigger>
                      <DropdownMenuContent align="end">
                        <DropdownMenuItem
                          onClick={() => startEditingItem(items.indexOf(item))}
                        >
                          <Pencil className="mr-2 h-4 w-4" />
                          <span>Edit</span>
                        </DropdownMenuItem>
                        <DropdownMenuItem
                          onClick={() => {
                            setItemToDelete(items.indexOf(item));
                            setIsDeleteDialogOpen(true);
                          }}
                        >
                          <Trash2 className="mr-2 h-4 w-4" />
                          <span>Delete</span>
                        </DropdownMenuItem>
                      </DropdownMenuContent>
                    </DropdownMenu>
                  </div>
                </div>
              ))}
          </div>
          <Dialog
            open={isDeleteDialogOpen}
            onOpenChange={setIsDeleteDialogOpen}
          >
            <DialogContent>
              <DialogHeader>
                <DialogTitle>Confirm Deletion</DialogTitle>
                <DialogDescription>
                  Are you sure you want to delete this item? This action cannot
                  be undone.
                </DialogDescription>
              </DialogHeader>
              <DialogFooter className="sm:justify-end">
                <Button
                  variant="outline"
                  onClick={() => setIsDeleteDialogOpen(false)}
                  className="mb-2 sm:mb-0 w-full sm:w-auto"
                >
                  Cancel
                </Button>
                <Button
                  variant="destructive"
                  onClick={removeItem}
                  className="w-full sm:w-auto"
                >
                  Delete
                </Button>
              </DialogFooter>
            </DialogContent>
          </Dialog>
          <Dialog
            open={isCategoryDialogOpen}
            onOpenChange={setIsCategoryDialogOpen}
          >
            <DialogContent>
              <DialogHeader>
                <DialogTitle>Add New Category</DialogTitle>
                <DialogDescription>
                  Enter the name for the new category.
                </DialogDescription>
              </DialogHeader>
              <Input
                type="text"
                value={newCategory}
                onChange={(e) => setNewCategory(e.target.value)}
                placeholder="New category name"
                className="my-4"
              />
              <DialogFooter className="sm:justify-end">
                <Button
                  variant="outline"
                  onClick={() => setIsCategoryDialogOpen(false)}
                  className="mb-2 sm:mb-0 w-full sm:w-auto"
                >
                  Cancel
                </Button>
                <Button onClick={addCategory} className="w-full sm:w-auto">
                  Add Category
                </Button>
              </DialogFooter>
            </DialogContent>
          </Dialog>
          <Dialog
            open={isEditCategoriesDialogOpen}
            onOpenChange={setIsEditCategoriesDialogOpen}
          >
            <DialogContent>
              <DialogHeader>
                <DialogTitle>Edit Categories</DialogTitle>
                <DialogDescription>
                  Edit or delete categories. Note: Deleting a category will move
                  its items to "Uncategorized".
                </DialogDescription>
              </DialogHeader>
              <div className="my-4 space-y-2">
                {editedCategories.map((category, index) => (
                  <div key={index} className="flex items-center space-x-2">
                    <Input
                      type="text"
                      value={category}
                      onChange={(e) =>
                        updateEditedCategory(index, e.target.value)
                      }
                      className="flex-grow"
                      disabled={category === "Uncategorized"}
                    />
                    {category !== "Uncategorized" && (
                      <Button
                        variant="destructive"
                        size="icon"
                        onClick={() => deleteCategory(index)}
                      >
                        <Trash2 className="h-4 w-4" />
                      </Button>
                    )}
                  </div>
                ))}
              </div>
              <DialogFooter className="sm:justify-end">
                <Button
                  variant="outline"
                  onClick={() => setIsEditCategoriesDialogOpen(false)}
                  className="mb-2 sm:mb-0 w-full sm:w-auto"
                >
                  Cancel
                </Button>
                <Button
                  onClick={saveEditedCategories}
                  className="w-full sm:w-auto"
                >
                  Save Changes
                </Button>
              </DialogFooter>
            </DialogContent>
          </Dialog>
        </div>
      </div>
    </div>
  );
}
