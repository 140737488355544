import { Link } from "@tanstack/react-router";
import {
  CalendarRangeIcon,
  FolderIcon,
  HouseIcon,
  Menu,
  Settings,
  SquareUserRound,
} from "lucide-react";

import { Button } from "@/components/ui/button";
import { Sheet, SheetContent, SheetTrigger } from "@/components/ui/sheet";
import React, { useState } from "react";
import { queryClient } from "@/ajax/queryClient";
import { QueryClientProvider } from "@tanstack/react-query";
import SidebarItem from "@/components/Sidebar/SidebarItem";
import { UserAvatar } from "@/components/UserAvatar/UserAvatar";
import { useRouterState } from "@tanstack/react-router";

const sideItems = [
  {
    icon: <HouseIcon />,
    label: "Home",
    route: "/provider/home",
  },
  {
    icon: <FolderIcon />,
    label: "Documents",
    route: "/provider/documents",
  },
  {
    icon: <CalendarRangeIcon />,
    label: "Shifts",
    route: "/provider/shifts",
  },

  {
    icon: <SquareUserRound />,
    label: "Profile",
    route: "/provider/profile",
  },
  // {
  //   icon: <DollarSign />,
  //   label: "Refer Friends",
  //   route: "/provider/referrals",
  // },
  {
    icon: <Settings />,
    label: "Settings",
    route: "/provider/settings",
  },
];

const mobileNavItems = [
  {
    icon: <HouseIcon />,
    label: "Home",
    route: "/provider/home",
  },
  {
    icon: <FolderIcon />,
    label: "Documents",
    route: "/provider/documents",
  },
  {
    icon: <CalendarRangeIcon />,
    label: "Shifts",
    route: "/provider/shifts",
  },
  {
    icon: <SquareUserRound />,
    label: "Profile",
    route: "/provider/profile",
  },
];

export function ProviderLayout({
  children,
  showSidebar = true,
}: {
  children: React.ReactNode;
  showSidebar?: boolean;
}) {
  const [openSheet, setOpenSheet] = useState(false);
  const router = useRouterState();

  return (
    <QueryClientProvider client={queryClient}>
      <div
        className={`min-h-screen w-full ${showSidebar ? "grid md:grid-cols-[220px_1fr] lg:grid-cols-[280px_1fr]" : ""}`}
      >
        {showSidebar && (
          <div
            id="static-sidebar"
            className="hidden border-r bg-muted/40 md:block"
          >
            <div className="flex h-full max-h-screen flex-col gap-2">
              <div className="flex h-14 items-center border-b px-4 lg:h-[60px] lg:px-6">
                <Link
                  to="/provider/home"
                  className="flex items-center gap-2 font-semibold"
                >
                  <img
                    alt="Saile Logo"
                    src="/static/images/logo.png"
                    className="w-[114px]"
                  />
                </Link>
              </div>
              <div className="flex-1">
                <nav className="grid items-start px-2 mt-6 text-sm font-medium lg:px-4">
                  {sideItems.map((item) => {
                    const isSelected = router.location.pathname.startsWith(
                      item.route,
                    );
                    return (
                      <React.Fragment key={item.label}>
                        <SidebarItem item={item} />
                        {/* {item.subItems && isSelected && (
                          <div className="ml-6 border-l border-muted-foreground/20 pl-2">
                            {item.subItems.map((subItem) => (
                              <SidebarItem
                                key={subItem.label}
                                item={subItem}
                                className="text-xs text-muted-foreground hover:text-primary py-1"
                              />
                            ))}
                          </div>
                        )} */}
                      </React.Fragment>
                    );
                  })}
                </nav>
              </div>
            </div>
          </div>
        )}
        <div className="flex flex-col h-screen">
          <header className="flex items-center gap-4 border-b bg-muted px-4">
            {showSidebar && (
              <Sheet open={openSheet} onOpenChange={setOpenSheet}>
                <SheetTrigger asChild>
                  <Button
                    variant="outline"
                    size="icon"
                    className="shrink-0 md:hidden"
                  >
                    <Menu className="h-5 w-5" />
                    <span className="sr-only">Toggle navigation menu</span>
                  </Button>
                </SheetTrigger>
                <SheetContent
                  id="sidebar"
                  side="left"
                  className="flex flex-col"
                >
                  <img
                    alt="Saile Logo"
                    src="/static/images/logo.png"
                    className="ml-2 mb-4 w-[114px]"
                  />
                  <nav className="text-lg font-medium">
                    {sideItems.map((item) => {
                      const isSelected = router.location.pathname.startsWith(
                        item.route,
                      );
                      return (
                        <React.Fragment key={item.label}>
                          <SidebarItem
                            onClick={() => setOpenSheet(false)}
                            item={item}
                          />
                          {/* {item.subItems && isSelected && (
                            <div className="ml-6 border-l border-muted-foreground/20 pl-2">
                              {item.subItems.map((subItem) => (
                                <SidebarItem
                                  key={subItem.label}
                                  item={subItem}
                                  onClick={() => setOpenSheet(false)}
                                  className="text-sm text-muted-foreground hover:text-primary py-1"
                                />
                              ))}
                            </div>
                          )} */}
                        </React.Fragment>
                      );
                    })}
                  </nav>
                </SheetContent>
              </Sheet>
            )}
            <div className="w-full flex-1"></div>
            <UserAvatar minimal={!showSidebar} />
          </header>

          <main className="flex-1 overflow-y-auto bg-gray-100">{children}</main>

          {/* Mobile Bottom Navigation */}
          {showSidebar && (
            <div
              id="mobile-bottom-nav"
              className="border-t bg-white shadow-[0_-4px_10px_rgba(0,0,0,0.1)] md:hidden h-[70px]"
            >
              <nav className="flex justify-around items-center h-full">
                {mobileNavItems.map((item) => {
                  const isSelected = router.location.pathname.startsWith(
                    item.route,
                  );
                  return (
                    <Link
                      key={item.label}
                      to={item.route}
                      className={`flex flex-col items-center justify-center w-full h-full transition-colors ${
                        isSelected
                          ? "text-primary border-t-[3px] border-primary bg-primary/5"
                          : "text-gray-600 hover:text-primary/80 hover:bg-primary/5 active:bg-primary/10"
                      }`}
                    >
                      {React.cloneElement(item.icon, {
                        className: `h-6 w-6 ${isSelected ? "stroke-[2.5px]" : ""}`,
                      })}
                      <span
                        className={`text-xs mt-1.5 ${isSelected ? "font-semibold" : ""}`}
                      >
                        {item.label}
                      </span>
                    </Link>
                  );
                })}
              </nav>
            </div>
          )}
        </div>
      </div>
    </QueryClientProvider>
  );
}
