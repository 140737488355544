import { apiRequestDeleteWithRetry } from "../utils/apiRequest";

type DeleteAccountResponse = {
  success: boolean;
};
export const deleteAccount = async ({
  confirmation,
}: {
  confirmation: string;
}): Promise<DeleteAccountResponse> => {
  const response = await apiRequestDeleteWithRetry({
    path: "/account",
    body: {
      confirmation,
    },
  });
  return response.data;
};
