import axios from "axios";
import { apiRequestGetWithRetry } from "../utils/apiRequest";

interface NPIAddress {
  country_code: string;
  country_name: string;
  address_purpose: string;
  address_type: string;
  address_1: string;
  city: string;
  state: string;
  postal_code: string;
  telephone_number: string;
  fax_number?: string;
}

interface NPIBasic {
  first_name: string;
  last_name: string;
  middle_name?: string;
  credential: string;
  sole_proprietor: string;
  gender: string;
  enumeration_date: string;
  last_updated: string;
  certification_date: string;
  status: string;
  name_prefix?: string;
}

interface NPITaxonomy {
  code: string;
  taxonomy_group: string;
  desc: string;
  state: string;
  license: string;
  primary: boolean;
}

interface NPIOtherName {
  type: string;
  code: string;
  credential: string;
  first_name: string;
  last_name: string;
  middle_name?: string;
  prefix?: string;
}

export interface NPIResult {
  created_epoch: string;
  enumeration_type: string;
  last_updated_epoch: string;
  number: string;
  addresses: NPIAddress[];
  practiceLocations: any[];
  basic: NPIBasic;
  taxonomies: NPITaxonomy[];
  identifiers: any[];
  endpoints: any[];
  other_names: NPIOtherName[];
}

export const getNPIDetails = async (
  firstName: string,
  lastName: string,
): Promise<NPIResult[]> => {
  return await apiRequestGetWithRetry({
    path: `/hhs-gov/npi`,
    params: {
      firstName,
      lastName,
    },
  });
};
