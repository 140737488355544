import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader } from "@/components/ui/card";
import { cn } from "@/lib/utils";
import { FileBadge, Pencil } from "lucide-react";
import { CertificationForm } from "./CertificationForm";
import { formatDate } from "date-fns";

export function CertificationsCard({
  editable,
  isEditing,
  onEditChange,
  certifications,
}: {
  editable: boolean;
  isEditing: boolean;
  onEditChange: (editing: boolean) => void;
  certifications: {
    dea: any;
    cds: any;
    bls: any;
    acls: any;
  };
}) {
  return (
    <Card
      className={cn("mt-4", {
        "shadow-none border-none": isEditing,
      })}
    >
      <CardHeader className="pb-1">
        <div className="flex items-center justify-between">
          <h3 className="text-lg font-semibold">Other Certifications</h3>
          {isEditing || !editable ? null : (
            <Button
              variant="ghost"
              size="icon"
              onClick={() => onEditChange(true)}
            >
              <Pencil className="h-4 w-4" />
            </Button>
          )}
        </div>
      </CardHeader>
      <CardContent className="space-y-2">
        {isEditing ? (
          <CertificationForm
            certData={certifications}
            onSave={() => onEditChange(false)}
          />
        ) : (
          <>
            {certifications.dea.map((dea: any, index: number) => (
              <div key={index} className="flex items-center justify-between">
                <div className="flex items-center gap-3">
                  <FileBadge className="h-5 w-5 text-gray-500" />
                  <div>
                    <div className="font-semibold">DEA Certification</div>
                    <div className="text-sm text-gray-600">
                      #{dea.value.number}
                    </div>
                    <div className="text-sm text-gray-500">
                      Exp. {formatDate(dea.value.expiration_date, "MMM yyyy")}
                    </div>
                  </div>
                </div>
              </div>
            ))}
            {certifications.cds.map((cds: any, index: number) => (
              <div key={index} className="flex items-center justify-between">
                <div className="flex items-center gap-3">
                  <FileBadge className="h-5 w-5 text-gray-500" />
                  <div>
                    <div className="font-semibold">CDS Certification</div>
                    <div className="text-sm text-gray-600">
                      #{cds.value.number}
                    </div>
                    <div className="text-sm text-gray-500">
                      Exp. {formatDate(cds.value.expiration_date, "MMM yyyy")}
                    </div>
                  </div>
                </div>
              </div>
            ))}
            {certifications.bls.map((bls: any, index: number) => (
              <div key={index} className="flex items-center justify-between">
                <div className="flex items-center gap-3">
                  <FileBadge className="h-5 w-5 text-gray-500" />
                  <div>
                    <div className="font-semibold">BLS Certification</div>
                    <div className="text-sm text-gray-600">
                      #{bls.value.ecard_code}
                    </div>
                    <div className="text-sm text-gray-500">
                      Exp. {formatDate(bls.value.expiration_date, "MMM yyyy")}
                    </div>
                  </div>
                </div>
              </div>
            ))}
            {certifications.acls.map((acls: any, index: number) => (
              <div key={index} className="flex items-center justify-between">
                <div className="flex items-center gap-3">
                  <FileBadge className="h-5 w-5 text-gray-500" />
                  <div>
                    <div className="font-semibold">ACLS Certification</div>
                    <div className="text-sm text-gray-600">
                      #{acls.value.ecard_code}
                    </div>
                    <div className="text-sm text-gray-500">
                      Exp. {formatDate(acls.value.expiration_date, "MMM yyyy")}
                    </div>
                  </div>
                </div>
              </div>
            ))}
            {certifications.dea.length === 0 &&
              certifications.cds.length === 0 &&
              certifications.bls.length === 0 &&
              certifications.acls.length === 0 && (
                <div className="text-center text-gray-500">
                  No certifications added yet.
                </div>
              )}
          </>
        )}
      </CardContent>
    </Card>
  );
}
