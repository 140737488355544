import React, { useEffect, useState } from "react";
import { Outlet, useRouter } from "@tanstack/react-router";
import { WifiOff } from "lucide-react";
import { Alert, AlertTitle, AlertDescription } from "@/components/ui/alert";

const OfflineHandler = () => {
  const [isOnline, setIsOnline] = useState(window.navigator.onLine);
  const router = useRouter();

  useEffect(() => {
    const handleOnline = () => {
      setIsOnline(true);
    };

    const handleOffline = () => {
      setIsOnline(false);
    };

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, [router]);

  return (
    <>
      <Outlet />
      {!isOnline && (
        <div className="fixed inset-0 bg-background/80 backdrop-blur-sm z-50">
          <div className="fixed inset-0 flex items-center justify-center">
            <Alert
              variant="destructive"
              className="border-2 shadow-lg max-w-md bg-background"
            >
              <div className="flex items-center gap-2">
                <WifiOff className="h-5 w-5 flex-shrink-0" />
                <div className="flex-1">
                  <AlertTitle className="text-lg font-semibold mb-1">
                    No Internet Connection
                  </AlertTitle>
                  <AlertDescription className="text-sm">
                    Please connect to the internet to continue using the app.
                    The app requires an active internet connection to function
                    properly.
                  </AlertDescription>
                </div>
              </div>
            </Alert>
          </div>
        </div>
      )}
    </>
  );
};

export default OfflineHandler;
