import * as LiveUpdates from "@capacitor/live-updates";
import { App, URLOpenListenerEvent } from "@capacitor/app";
import { Capacitor } from "@capacitor/core";
import { router } from "@/router";

export async function initializeApp() {
  // Check if it's not web
  if (Capacitor.getPlatform() === "web") {
    return;
  }

  // Register event to fire each time user resumes the app
  App.addListener("resume", async () => {
    try {
      if (localStorage.shouldReloadApp === "true") {
        await LiveUpdates.reload();
      } else {
        const result = await LiveUpdates.sync();
        localStorage.shouldReloadApp = result.activeApplicationPathChanged;
      }
    } catch (error) {
      // If offline or error occurs, continue without sync
      console.log("Failed to sync updates on resume:", error);
    }
  });

  App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
    const path = event.url.split(".com").pop();
    console.log("Universal link", path);
    router.navigate({
      to: path,
    })
  });

  try {
    const result = await LiveUpdates.sync();
    if (result.activeApplicationPathChanged) {
      await LiveUpdates.reload();
    }
  } catch (error) {
    // If offline or error occurs, continue without sync
    console.log("Failed to sync updates on init:", error);
  } finally {
    // Always hide splash screen regardless of sync result
    // await SplashScreen.hide();
  }
}
