import { createFileRoute } from "@tanstack/react-router";
import { Card } from "@/components/ui/card";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { Link } from "@tanstack/react-router";
import { format } from "date-fns";
import { useSuspenseQuery } from "@tanstack/react-query";
import { getUserProfileSharesQueryOptions } from "@/ajax/queries";

export const Route = createFileRoute("/_org/org/provider-profiles/")({
  component: () => {
    const { user } = Route.useRouteContext();
    const { data: providers } = useSuspenseQuery(
      getUserProfileSharesQueryOptions(user.id),
    );

    return (
      <div className="container mx-auto py-8">
        <div className="max-w-5xl mx-auto">
          <h1 className="text-2xl font-semibold mb-4">
            Provider Profiles Shared with You
          </h1>
          <Card className="p-0">
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>Name</TableHead>
                  <TableHead>Specialty</TableHead>
                  <TableHead>Location</TableHead>
                  <TableHead>Shared Date</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {providers.map((provider) => (
                  <TableRow key={provider.id}>
                    <TableCell>
                      <Link
                        to="/org/provider-profile/$userId"
                        params={{ userId: provider.user_id }}
                        className="text-blue-600 hover:underline"
                      >
                        {provider.name_value?.first_name}{" "}
                        {provider.name_value?.last_name}
                      </Link>
                    </TableCell>
                    <TableCell>
                      {provider.specialty_value?.specialty_name}
                    </TableCell>
                    <TableCell>
                      {provider.address_value?.city},{" "}
                      {provider.address_value?.state}
                    </TableCell>
                    <TableCell>
                      {format(new Date(provider.created_at), "MMM dd, yyyy")}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Card>
        </div>
      </div>
    );
  },
});
