import { Button } from "@/components/ui/button";
import { Capacitor } from "@capacitor/core";
import { useEffect, useState } from "react";
import { SignInWithApple } from "@capacitor-community/apple-sign-in";
import { useNavigate } from "@tanstack/react-router";
import { supabase } from "@/ajax/clients/supabase";
import { getCredentialsFields } from "@/ajax/credentials/getCredentialsFields";
import { saveCredentialsFields } from "@/ajax/credentials/saveCredentialsFields";
import Loading from "@/components/Loading";
import AppleLogo from "@/components/icons/AppleLogo";

export const AppleSignInButton = ({
  onError,
}: {
  onError: (error: string) => void;
}) => {
  const [name, setName] = useState<null | {
    firstName: string | null;
    lastName: string | null;
  }>(null);
  const [isAppleSigningIn, setIsAppleSigningIn] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    // Only load Apple Sign In script if we're on web
    if (!Capacitor.isNativePlatform()) {
      const script = document.createElement("script");
      script.src =
        "https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js";
      script.async = true;
      script.onload = () => {
        // Initialize Apple Sign In
        (window as any).AppleID.auth.init({
          clientId: "com.saileapp.webapp",
          scope: "email name",
          redirectURI: `https://${window.location.hostname}/auth-provider/signin`,
          state: "origin:web",
          usePopup: true,
        });
      };
      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      };
    }
  }, []);

  const handleAppleSignIn = async () => {
    if (!navigator.onLine) {
      onError("No internet connection. Please try again.");
      return;
    }

    setIsAppleSigningIn(true);

    let idToken: string | null = null;
    let userFirstName: string | null = null;
    let userLastName: string | null = null;
    try {
      if (Capacitor.isNativePlatform()) {
        // Native iOS
        const result = await SignInWithApple.authorize({
          clientId: "com.saileapp.app",
          redirectURI: "",
          scopes: "email name",
        });
        idToken = result.response.identityToken;
        userFirstName = result.response.givenName;
        userLastName = result.response.familyName;
      } else {
        // Web version
        const result: {
          authorization: {
            code: string;
            id_token: string;
            state: string;
          };
          user: {
            email: string;
            name: {
              firstName: string;
              lastName: string;
            };
          };
        } = await (window as any).AppleID.auth.signIn();
        idToken = result.authorization.id_token;
        if (result?.user?.name) {
          userFirstName = result.user.name.firstName;
          userLastName = result.user.name.lastName;
        }
      }

      /* There is a quirk with Apple Sign In where the first name and last name are
          only returned on the first sign in. If there is a network error, we
          will not be able to get the name. Save the name for the future */
      if (userFirstName && userLastName) {
        setName({
          firstName: userFirstName,
          lastName: userLastName,
        });
      }

      const { error } = await supabase.auth.signInWithIdToken({
        provider: "apple",
        token: idToken,
      });

      if (error) {
        console.error("Apple Sign In Error:", error);
        onError("Apple sign in failed. Please try again.");
        setIsAppleSigningIn(false);
        return;
      }

      // Use the local variables instead of relying on state
      const finalFirstName = userFirstName || name?.firstName || null;
      const finalLastName = userLastName || name?.lastName || null;

      if (finalFirstName && finalLastName) {
        // Check if the user already has a name, we don't want to overwrite it
        // with the Apple name.
        const current = await getCredentialsFields({
          fieldCodes: ["first_name", "last_name"],
        });
        if (!current.first_name) {
          // Update the data from Apple. Ideally Supabase will handle
          // this in the future.
          await saveCredentialsFields({
            fieldValues: [
              {
                field_code: "name",
                index: 1,
                value: {
                  first_name: finalFirstName,
                  last_name: finalLastName,
                },
                delete: false,
              },
            ],
          });
        }
      }

      // Send the authorization code/token to your backend
      // console.log('Apple Sign In Success:', result.response);
      setIsAppleSigningIn(false);
      navigate({ to: "/" });
    } catch (error) {
      console.error("Apple Sign In Error:", error);
      onError("Apple sign in failed. Please try again.");
      setIsAppleSigningIn(false);
      return;
    }
  };
  return (
    <Button
      variant="ghost"
      size="lg"
      onClick={handleAppleSignIn}
      type="button"
      disabled={isAppleSigningIn}
      className="w-full flex items-center justify-center gap-3 border border-black bg-white hover:bg-gray-50"
    >
      {isAppleSigningIn ? (
        <Loading className="h-5 w-5" />
      ) : (
        <AppleLogo className="w-5 h-5 mb-1" />
      )}
      Sign in with Apple
    </Button>
  );
};
