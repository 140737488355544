import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import { useMutation } from "@tanstack/react-query";
import { useState } from "react";
import { shareProfile } from "@/ajax/profile/shareProfile";
import { Checkbox } from "@/components/ui/checkbox";

const formSchema = z.object({
  email: z.string().email("Please enter a valid email address"),
  includeFiles: z.enum(["yes", "no"], {
    required_error: "Please select whether to include documents",
  }),
  shareWithOthers: z.boolean(),
});

type FormValues = z.infer<typeof formSchema>;

interface SharingProfileModalProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
}

export function SharingProfileModal({
  open,
  onOpenChange,
}: SharingProfileModalProps) {
  const [sharedEmail, setSharedEmail] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);

  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: "",
      shareWithOthers: true,
    },
  });

  const mutation = useMutation({
    mutationFn: shareProfile,
    onSuccess: (_, variables) => {
      setError(null);
      setSharedEmail(variables.email);
      form.reset();
    },
    onError: () => {
      setError("Failed to share profile. Please check the email and try again.");
    }
  });

  function onSubmit(values: FormValues) {
    setError(null);
    mutation.mutate({
      email: values.email,
      includeFiles: values.includeFiles === "yes",
    });
  }

  function handleClose() {
    setSharedEmail(null);
    setError(null);
    onOpenChange(false);
  }

  return (
    <Dialog open={open} onOpenChange={handleClose}>
      <DialogContent className="sm:max-w-[425px] rounded-lg border shadow-lg bg-white dark:bg-gray-900">
        {sharedEmail ? (
          <div className="space-y-8 py-6">
            <div className="flex flex-col items-center justify-center space-y-4">
              <div className="h-16 w-16 bg-green-100 dark:bg-green-900 rounded-full flex items-center justify-center">
                <svg
                  className="h-8 w-8 text-green-600 dark:text-green-400"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M5 13l4 4L19 7"
                  />
                </svg>
              </div>
              <div className="text-center space-y-2">
                <h3 className="text-2xl font-semibold tracking-tight text-gray-900 dark:text-gray-100">
                  Profile Shared!
                </h3>
                <div className="space-y-1">
                  <p className="text-sm text-gray-500 dark:text-gray-400">
                    Your profile has been shared with:
                  </p>
                  <p className="text-base font-medium text-gray-900 dark:text-gray-100">
                    {sharedEmail}
                  </p>
                </div>
              </div>
            </div>
            <Button
              onClick={handleClose}
              className="w-full h-12 text-base font-medium transition-colors"
            >
              Close
            </Button>
          </div>
        ) : (
          <>
            <DialogHeader className="space-y-3 pb-4">
              <DialogTitle className="text-2xl font-semibold tracking-tight">
                Share Your Profile
              </DialogTitle>
              <ul className="space-y-3 text-left text-sm list-disc pl-4">
                <li>
                  The recipient will have access to your profile and optionally
                  your credential documents
                </li>
                <li>
                  Perfect for job applications and credential verification
                </li>
                <li className="font-medium text-red-600 dark:text-yellow-500">
                  Only share with people you trust!
                </li>
              </ul>
            </DialogHeader>
            <Form {...form}>
              <form
                onSubmit={form.handleSubmit(onSubmit)}
                className="space-y-6"
              >
                <FormField
                  control={form.control}
                  name="email"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel className="text-sm font-medium">
                        Email Address
                      </FormLabel>
                      <FormControl>
                        <Input
                          type="email"
                          autoComplete="off"
                          placeholder="email@example.com"
                          {...field}
                          className="h-11 rounded-md border-gray-200 dark:border-gray-700 focus:ring-2 focus:ring-offset-2"
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="includeFiles"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel className="text-sm font-medium">
                        Include your credential documents?
                      </FormLabel>
                      <Select name={field.name} onValueChange={field.onChange}>
                        <FormControl>
                          <SelectTrigger className="h-11 rounded-md border-gray-200 dark:border-gray-700">
                            <SelectValue placeholder="Select an option" />
                          </SelectTrigger>
                        </FormControl>
                        <SelectContent>
                          <SelectItem value="yes">
                            Yes, include my credential documents
                          </SelectItem>
                          <SelectItem value="no">No, profile only</SelectItem>
                        </SelectContent>
                      </Select>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="shareWithOthers"
                  render={({ field }) => (
                    <FormItem>
                       <div className="items-top flex space-x-2">
                      
                        <Checkbox name={field.name} onCheckedChange={field.onChange} checked={field.value} />
                        <div className="grid leading-none">
                          <FormLabel className="text-sm font-medium">
                            Allow recipient to share your profile
                          </FormLabel>
                          <p className="text-sm text-muted-foreground">
                            Your profile often needs to be shared with other staff members.
                          </p>
                        </div>
                        
                      </div>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                {error && (
                <div className="text-sm text-red-600 dark:text-red-400 font-medium">
                  {error}
                </div>
              )}
                <Button
                  type="submit"
                  disabled={mutation.isPending}
                  className="w-full h-12 text-base font-medium transition-colors"
                >
                  {mutation.isPending ? "Sharing..." : "Share Profile"}
                </Button>
              </form>
            </Form>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
}
