import React from "react";
import { Dialog, DialogContent, DialogDescription, DialogHeader } from "../ui/dialog";
import { FileType } from "@/ajax/documents/getFiles";
import FileVersionPreview from "./FileVersionPreview";
import { DialogTitle } from "@radix-ui/react-dialog";

export const FilePreviewModal = ({
  file,
  userId,
  onClose,
  editable = true,
}: {
  onClose: () => void;
  userId: string;
  file: FileType;
  editable?: boolean;
}) => {
  return (
    <Dialog
      open={true}
      onOpenChange={(open) => {
        if (!open) {
          onClose();
        }
      }}
    >
      <DialogContent className="overflow-auto pt-10">
        <DialogTitle className="sr-only">File Preview</DialogTitle>
        <DialogDescription className="sr-only">View the file and all the details</DialogDescription>

        <DialogHeader>
          <FileVersionPreview userId={userId} file={file} onDelete={onClose} editable={editable} />
        </DialogHeader>
      </DialogContent>
    </Dialog>
  );
};

export default FilePreviewModal;
