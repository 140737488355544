import React, { useState } from "react";
import {
  Bell,
  BriefcaseBusiness,
  BriefcaseMedical,
  CheckIcon,
  ClipboardCheck,
  Upload,
} from "lucide-react";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { Button } from "@/components/ui/button";
import { OnboardingStep } from "../utils/getOnboardingSteps";

export default function OnboardingStepper({
  stepIndex,
  onboardingSteps,
}: {
  stepIndex: number;
  onboardingSteps: OnboardingStep[];
}) {
  const [openTooltips, setOpenTooltips] = useState<string[]>([]);

  return (
    <div className="pl-5">
      <ol className="hidden lg:block relative text-gray-500 border-s border-gray-200 dark:border-gray-700 dark:text-gray-400">
        {onboardingSteps.map((step, index) => {
          let bgClassName =
            "absolute flex items-center justify-center w-10 h-10 rounded-full -start-5";
          let iconClassName = "w-4 h-4 text-white";
          if (stepIndex > index) {
            bgClassName += " bg-green-700";
          } else if (stepIndex === index) {
            bgClassName += " bg-blue-800";
          } else {
            bgClassName += " bg-slate-400";
          }

          const Icon = step.icon;

          return (
            <li key={step.title} className="[&:not(:last-child)]:mb-10 ms-8">
              <span className={bgClassName}>
                {stepIndex > index ? (
                  <CheckIcon className={iconClassName} />
                ) : (
                  <Icon className={iconClassName} />
                )}
              </span>
              <h3 className="font-medium leading-9">{step.title}</h3>
            </li>
          );
        })}
      </ol>
      <ol className="flex items-center w-full lg:hidden">
        {onboardingSteps.map((step, index) => {
          let bgClassName =
            "flex items-center justify-center w-10 h-10 rounded-full shrink-0";
          let iconClassName = "w-3.5 h-3.5 text-white lg:w-4 lg:h-4 text-white";
          if (stepIndex > index) {
            bgClassName += " bg-green-700";
          } else if (stepIndex === index) {
            bgClassName += " bg-blue-800";
          } else {
            bgClassName += " bg-slate-400";
          }

          const onOpenChange = (open: boolean) => {
            setOpenTooltips((prevState) => {
              if (open) {
                if (openTooltips.includes(step.title)) {
                  return prevState;
                } else {
                  return prevState.concat([step.title]);
                }
              } else {
                return prevState.filter((item) => item !== step.title);
              }
            });
          };
          const Icon = step.icon;

          return (
            <li
              key={step.title}
              className={`flex items-center w-full dark:text-blue-500 ${index === onboardingSteps.length - 1 ? "" : "after:content-[''] after:w-full after:h-1 after:border-b after:border-slate-300 after:border-4 after:inline-block dark:after:border-blue-800"}`}
            >
              <TooltipProvider>
                <Tooltip
                  delayDuration={300}
                  open={openTooltips.includes(step.title)}
                  onOpenChange={onOpenChange}
                >
                  <TooltipTrigger asChild>
                    <Button
                      variant="none"
                      size="none"
                      onClick={() => {
                        onOpenChange(true);
                      }}
                      className={bgClassName}
                    >
                      {stepIndex > index ? (
                        <CheckIcon className={iconClassName} />
                      ) : (
                        <Icon className={iconClassName} />
                      )}
                    </Button>
                  </TooltipTrigger>
                  <TooltipContent>{step.title}</TooltipContent>
                </Tooltip>
              </TooltipProvider>
            </li>
          );
        })}
      </ol>
    </div>
  );
}
