import React, { useState, useRef, useEffect } from "react";
import { motion } from "framer-motion";
import { AlertCircle, Mail } from "lucide-react";
import Loading from "@/components/Loading";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import { signInOTPToken } from "@/ajax/auth/signInOTPToken";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { createReferral } from "@/ajax/referrals/createReferral";
import { getEnvVar } from "@/common/utils/environment";
import { InputOTP, InputOTPGroup, InputOTPSlot } from "../ui/input-otp";
import { saveLastEmail } from "@/common/utils/lastEmail";

const LOGO_PATH = "/static/images/logo.png";
const AUTH_CODE_LENGTH = Number(getEnvVar("VITE_PUBLIC_AUTH_CODE_LENGTH") ?? 8);

const EmailVerification = ({
  email,
  referralCode,
  onComplete,
  onBack,
  onResend,
}: {
  email: string;
  referralCode?: string;
  onComplete: () => void;
  onBack: () => void;
  onResend: () => Promise<void>;
}) => {
  const [code, setCode] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isResending, setIsResending] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);

  useEffect(() => {
    inputRefs.current = inputRefs.current.slice(0, AUTH_CODE_LENGTH);
  }, []);

  const handleCodeChange = (value: string) => {
    setCode(value);
    if (value.length === AUTH_CODE_LENGTH) {
      handleSubmit(value);
    }
  };

  const handleSubmit = async (submittedCode: string) => {
    if (submittedCode.length === AUTH_CODE_LENGTH) {
      if (!navigator.onLine) {
        setError("Please check your internet connection and try again");
        return;
      }

      setIsSubmitting(true);
      const { error, user } = await signInOTPToken({
        email,
        token: submittedCode,
      });

      if (user?.email) {
        saveLastEmail(user.email);
      }

      if (user && referralCode) {
        await createReferral({
          referralCode: referralCode,
        });
      }

      if (!error) {
        onComplete();
      } else {
        setError("Invalid Code");
        setIsSubmitting(false);
      }
    }
  };

  const handleResend = async () => {
    if (!navigator.onLine) {
      setError("Please check your internet connection and try again");
      return;
    }

    setIsResending(true);
    await onResend();
    setIsResending(false);
    // Clear errors and code after resend
    setError(null);
    setCode("");

    // Close dialog
    setDialogOpen(false);

    setTimeout(() => {
      // Focus first input after clearing
      inputRefs.current[0]?.focus();
    }, 0);
  };

  return (
    <main className="min-h-screen flex flex-col items-center justify-center md:bg-gray-100 p-4">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="bg-white p-8 rounded-lg max-w-xl w-full md:border"
      >
        <div className="text-center mb-8">
          <img
            src={LOGO_PATH}
            alt="Saile Company Logo"
            width={150}
            height={60}
            className="mx-auto mb-6"
          />
          <motion.div
            initial={{ scale: 0.5, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            transition={{ delay: 0.2, duration: 0.5 }}
          >
            <Mail size={64} className="mx-auto text-primary mb-4" />
          </motion.div>
          <h1 className="text-3xl font-bold text-gray-800 mb-2">
            Check Your Email Inbox
          </h1>
          <p className="text-gray-600 mb-2">We've sent a code to:</p>
          <p className="text-primary font-semibold text-lg">{email}</p>
        </div>

        <div className="space-y-6">
          {error && (
            <Alert variant="destructive">
              <AlertCircle className="h-4 w-4" />
              <AlertTitle>Error</AlertTitle>
              <AlertDescription>{error}</AlertDescription>
            </Alert>
          )}
          <div className="flex justify-center">
            <div>
              <div className="font-bold mb-2">EMAIL CODE:</div>
              <InputOTP
                onChange={handleCodeChange}
                disabled={isSubmitting}
                value={code}
                autoFocus
                maxLength={AUTH_CODE_LENGTH}
              >
                <InputOTPGroup>
                  {Array.from({ length: AUTH_CODE_LENGTH }).map((_, index) => (
                    <InputOTPSlot key={index} index={index} />
                  ))}
                </InputOTPGroup>
              </InputOTP>
            </div>
          </div>
          <div className="flex justify-center items-center">
            {isSubmitting && <Loading className="w-10 h-10" />}
          </div>
        </div>

        <div className="text-center mt-4">
          <Dialog open={dialogOpen} onOpenChange={setDialogOpen}>
            <DialogTrigger asChild>
              <Button variant="link" className="text-indigo-600">
                Didn't receive the code?
              </Button>
            </DialogTrigger>
            <DialogContent>
              <DialogHeader>
                <DialogTitle>Didn't receive the code?</DialogTitle>
                <DialogDescription>
                  Choose what you'd like to do next
                </DialogDescription>
              </DialogHeader>
              <div className="space-y-4 py-4">
                <Button
                  onClick={handleResend}
                  disabled={isResending}
                  className="w-full"
                >
                  {isResending ? <Loading className="w-4 h-4 mr-2" /> : null}
                  Resend code to {email}
                </Button>
                <div className="relative">
                  <div className="absolute inset-0 flex items-center">
                    <span className="w-full border-t" />
                  </div>
                  <div className="relative flex justify-center text-xs uppercase">
                    <span className="bg-background px-2 text-muted-foreground">
                      Or
                    </span>
                  </div>
                </div>
                <Button onClick={onBack} variant="outline" className="w-full">
                  Try a different email
                </Button>
              </div>
            </DialogContent>
          </Dialog>
        </div>
      </motion.div>
    </main>
  );
};

export default EmailVerification;
