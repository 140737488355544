import React, { useMemo, useState, useRef } from "react";
import { useSuspenseQuery } from "@tanstack/react-query";
import { getCredentialsFieldsQueryOptions } from "@/ajax/queries";
import { differenceInYears, format, parse } from "date-fns";
import { CertificationsCard } from "@/features/profile/components/CertificationsCard";
import { WorkHistoryCard } from "@/features/profile/components/WorkHistoryCard";
import { StateLicensesCard } from "@/features/profile/components/StateLicensesCard";
import { AdmittingPrivilegesCard } from "@/features/profile/components/AdmittingPrivilegesCard";
import { EducationCard } from "@/features/profile/components/EducationCard";
import { ReferencesCard } from "@/features/profile/components/ReferencesCard";
import { StatsSection } from "@/features/profile/components/StatsSection";
import { PrimaryDetailsCard } from "@/features/profile/components/PrimaryDetailsCard";
import { SpecialtiesCard } from "./SpecialtiesCard";
import DocumentsCard from "./DocumentsCard";

export const ProviderProfilePage = ({
  editable,
  user,
}: {
  editable: boolean;
  user: {
    id: string;
    phone: string | null;
    email: string | null;
    profile_image_path: string | null;
  };
}) => {
  const [editingSections, setEditingSections] = useState<string[]>([]);
  const {
    data: {
      name,
      address,
      work_experience,
      npi_number,
      professional_education,
      professional_training,
      fifth_pathway_education,
      undergraduate_education,
      state_medical_licenses,
      dea,
      cds,
      bls,
      acls,
      specialty,
      admitting_privileges,
      professional_references,
      healthcare_role,
      linkedin_url,
    },
  } = useSuspenseQuery(
    getCredentialsFieldsQueryOptions(
      [
        "name",
        "address",
        "work_experience",
        "npi_number",
        "professional_education",
        "professional_training",
        "fifth_pathway_education",
        "undergraduate_education",
        "state_medical_licenses",
        "dea",
        "cds",
        "bls",
        "acls",
        "specialty",
        "admitting_privileges",
        "professional_references",
        "healthcare_role",
        "linkedin_url",
      ],
      user.id,
    ),
  );

  const specialties = specialty?.values
    .filter((val) => val.value)
    .map(
      (val) =>
        val.value as {
          specialty_code: string;
          initial_certification_date: string;
          expiration_date: string;
          certifying_board: string;
          state: string;
          certification_number: string;
        },
    );

  const specialtyCodes = specialties?.map((s) => s.specialty_code) || [];
  const nameValue = name?.values[0].value as {
    first_name: string;
    middle_name: string;
    last_name: string;
    suffix_name: string;
  } | null;
  const fullName = [nameValue?.first_name, nameValue?.middle_name, nameValue?.last_name].filter(Boolean).join(" ");
  const addressValue = address?.values[0].value as {
    address_1: string;
    address_2: string;
    city: string;
    state: string;
    zip_code: string;
  } | null;
  const npiNumber = npi_number?.values[0].value as string | null;

  const workExperiences =
    work_experience?.values.filter((val) => val.value) || [];
  const professionalEducation =
    professional_education?.values.filter((val) => val.value) || [];
  const professionalTraining =
    professional_training?.values.filter((val) => val.value) || [];
  const fifthPathwayEducation =
    fifth_pathway_education?.values.filter((val) => val.value) || [];
  const undergraduateEducation =
    undergraduate_education?.values.filter((val) => val.value) || [];
  const stateLicenses =
    state_medical_licenses?.values.filter((val) => val.value) || [];
  const deaCertification = dea?.values.filter((val) => val.value) || [];
  const cdsCertification = cds?.values.filter((val) => val.value) || [];
  const blsCertification = bls?.values.filter((val) => val.value) || [];
  const aclsCertification = acls?.values.filter((val) => val.value) || [];
  const admittingPrivileges =
    admitting_privileges?.values.filter((val) => val.value) || [];
  const referencesList =
    professional_references?.values.filter((val) => val.value) || [];
  const healthcareProfessionalType = (
    healthcare_role?.values[0].value as {
      healthcare_role_id: string;
    }
  )?.healthcare_role_id;
  const linkedinUrl = linkedin_url?.values[0].value as string | null;
  const toggleEditSection = (section: string) => {
    setEditingSections((prev) => {
      if (prev.includes(section)) {
        return prev.filter((s) => s !== section);
      }
      return [...prev, section];
    });
  };

  // Calculate years of experience from earliest work experience
  const yearsOfExperience = useMemo(() => {
    if (workExperiences.length === 0) return 0;

    const earliestDate = workExperiences.reduce((earliest, exp) => {
      if (!(exp.value as { start_date: string }).start_date) return earliest;
      const startDate = parse(
        (exp.value as { start_date: string }).start_date,
        "yyyy-MM-dd",
        new Date(),
      );
      return startDate < earliest ? startDate : earliest;
    }, new Date());

    const years = differenceInYears(new Date(), earliestDate);
    return years;
  }, [workExperiences]);

  const certificationsCount =
    blsCertification.length +
    aclsCertification.length +
    cdsCertification.length +
    deaCertification.length;

  return (
    <div className="max-w-3xl mx-auto bg-white py-8">
      {/* Profile Section */}
      <div className="p-4 md:p-8">
        <PrimaryDetailsCard
          editable={editable}
          userId={user.id}
          profileImagePath={user.profile_image_path}
          isEditing={editingSections.includes("primary-details")}
          onEditChange={() => toggleEditSection("primary-details")}
          phoneNumber={user.phone || ""}
          email={user.email || ""}
          address1={addressValue?.address_1}
          address2={addressValue?.address_2}
          city={addressValue?.city}
          state={addressValue?.state}
          zipCode={addressValue?.zip_code}
          firstName={nameValue?.first_name}
          lastName={nameValue?.last_name}
          middleName={nameValue?.middle_name}
          suffixName={nameValue?.suffix_name}
          specialtyCodes={specialtyCodes}
          npiNumber={npiNumber}
          healthcareProfessionalType={healthcareProfessionalType}
          linkedinUrl={linkedinUrl}
        />

        {/* Stats */}
        <StatsSection
          yearsOfExperience={yearsOfExperience}
          certificationsCount={certificationsCount}
          stateLicensesCount={stateLicenses.length}
        />

        <SpecialtiesCard
          editable={editable}
          isEditing={editingSections.includes("specialties")}
          onEditChange={() => toggleEditSection("specialties")}
          specialties={specialties}
        />

        {/* Documents Section */}
        <DocumentsCard userId={user.id} userName={fullName} />

        <WorkHistoryCard
          editable={editable}
          isEditing={editingSections.includes("work-history")}
          onEditChange={() => toggleEditSection("work-history")}
          workExperiences={workExperiences}
        />

        {/* Admitting Privileges */}
        <AdmittingPrivilegesCard
          editable={editable}
          isEditing={editingSections.includes("admitting-privileges")}
          onEditChange={() => toggleEditSection("admitting-privileges")}
          admittingPrivileges={admittingPrivileges}
        />

        {/* State Licenses */}
        <StateLicensesCard
          editable={editable}
          isEditing={editingSections.includes("state-licenses")}
          onEditChange={() => toggleEditSection("state-licenses")}
          stateLicenses={stateLicenses}
        />

        <CertificationsCard
          editable={editable}
          isEditing={editingSections.includes("certifications")}
          onEditChange={() => toggleEditSection("certifications")}
          certifications={{
            dea: deaCertification,
            cds: cdsCertification,
            bls: blsCertification,
            acls: aclsCertification,
          }}
        />

        <EducationCard
          editable={editable}
          isEditing={editingSections.includes("education")}
          onEditChange={() => toggleEditSection("education")}
          education={{
            professional_education: professionalEducation,
            professional_training: professionalTraining,
            fifth_pathway_education: fifthPathwayEducation,
            undergraduate_education: undergraduateEducation,
          }}
        />
        <ReferencesCard
          editable={editable}
          isEditing={editingSections.includes("references")}
          onEditChange={() => toggleEditSection("references")}
          references={referencesList}
        />
      </div>
    </div>
  );
};
