import { getSharedUserQueryOptions } from "@/ajax/queries";
import { ProviderProfilePage } from "@/features/profile/components/ProviderProfilePage";
import { useSuspenseQuery } from "@tanstack/react-query";
import { createFileRoute } from "@tanstack/react-router";
import { LockKeyhole } from "lucide-react";
import { Card, CardContent, CardHeader } from "@/components/ui/card";

export const Route = createFileRoute("/_org/org/provider-profile/$userId/")({
  errorComponent: () => {
    return (
      <div className="flex flex-col items-center justify-center min-h-[50vh] p-8">
          <Card className="max-w-md">
            <CardHeader>
              <div className="text-center">
                <LockKeyhole className="w-12 h-12 mx-auto mb-4 text-muted-foreground" />
                <h2 className="text-2xl font-semibold">Access Restricted</h2>
              </div>
            </CardHeader>
            <CardContent>
              <p className="text-muted-foreground text-center">
                You don't have permission to view this provider profile. If you
                believe this is a mistake, please contact the provider and ask
                them to share their profile with you.
              </p>
            </CardContent>
          </Card>
        </div>
    )
  },
  component: () => {
    const { userId } = Route.useParams();

    const { data: providerUser } = useSuspenseQuery(
      getSharedUserQueryOptions(userId),
    );
    return <ProviderProfilePage editable={false} user={providerUser} />;

  },
});
