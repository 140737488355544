import { apiRequestPostWithRetry } from "../utils/apiRequest";

export const createBillingSession = async (): Promise<{
  clientSecret: string;
}> => {
  return await apiRequestPostWithRetry({
    path: "/billing/account-session",
    retryOptions: {
      maxRetries: 1,
      retryDelay: 2000,
    },
    body: {},
  });
};
