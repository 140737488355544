import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { format } from "date-fns";
import { CalendarIcon } from "lucide-react";
import DatePicker from "react-datepicker";
import { UseFormReturn } from "react-hook-form";
import { CountrySelect } from "@/components/Selects/CountrySelect";
import { USStateSelect } from "@/components/Selects/USStateSelect";
import { Checkbox } from "@/components/ui/checkbox";

export const UndergraduateEducationFields = ({
  form,
  index,
}: {
  form: UseFormReturn;
  index: number;
}) => {
  return (
    <div className="space-y-4">
      <FormField
        control={form.control}
        name={`education.${index}.degree`}
        render={({ field }) => (
          <FormItem>
            <FormLabel>Degree</FormLabel>
            <FormControl>
              <Input {...field} />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />

      <FormField
        control={form.control}
        name={`education.${index}.program`}
        render={({ field }) => (
          <FormItem>
            <FormLabel>Program</FormLabel>
            <FormControl>
              <Input {...field} />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />

      <FormField
        control={form.control}
        name={`education.${index}.institution`}
        render={({ field }) => (
          <FormItem>
            <FormLabel>Institution</FormLabel>
            <FormControl>
              <Input {...field} />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />

      <div className="grid grid-cols-2 gap-4">
        <FormField
          control={form.control}
          name={`education.${index}.city`}
          render={({ field }) => (
            <FormItem>
              <FormLabel>City</FormLabel>
              <FormControl>
                <Input {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name={`education.${index}.state`}
          render={({ field }) => {
            const countryValue = form.watch(`education.${index}.country`);
            return (
              <FormItem>
                <FormLabel>State</FormLabel>
                <FormControl>
                  {countryValue === "United States" ? (
                    <USStateSelect {...field} />
                  ) : (
                    <Input {...field} />
                  )}
                </FormControl>
                <FormMessage />
              </FormItem>
            );
          }}
        />
      </div>
      <FormField
        control={form.control}
        name={`education.${index}.country`}
        render={({ field }) => (
          <FormItem>
            <FormLabel>Country</FormLabel>
            <FormControl>
              <CountrySelect {...field} />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />

      <div className="space-y-4">
        <div className="grid grid-cols-2 gap-4">
          <FormField
            control={form.control}
            name={`education.${index}.start_date`}
            render={({ field }) => (
              <FormItem>
                <FormLabel>Start Date</FormLabel>
                <div className="relative">
                  <DatePicker
                    selected={field.value as Date}
                    onChange={(date) => field.onChange(date)}
                    customInput={
                      <Input
                        value={
                          field.value ? format(field.value, "MM-dd-yyyy") : ""
                        }
                      />
                    }
                    dateFormat="MM-dd-yyyy"
                    wrapperClassName="w-full"
                  />
                  <CalendarIcon className="absolute right-3 top-1/2 -translate-y-1/2 h-4 w-4 opacity-50 pointer-events-none" />
                </div>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name={`education.${index}.end_date`}
            render={({ field }) => (
              <FormItem>
                <FormLabel>End Date</FormLabel>
                <div className="relative">
                  <DatePicker
                    selected={field.value}
                    onChange={(date) => field.onChange(date)}
                    customInput={
                      <Input
                        value={
                          field.value &&
                          !form.watch(`education.${index}.in_progress`)
                            ? format(field.value, "MM-dd-yyyy")
                            : ""
                        }
                        disabled={form.watch(`education.${index}.in_progress`)}
                      />
                    }
                    dateFormat="MM-dd-yyyy"
                    wrapperClassName="w-full"
                    disabled={form.watch(`education.${index}.in_progress`)}
                  />
                  <CalendarIcon className="absolute right-3 top-1/2 -translate-y-1/2 h-4 w-4 opacity-50 pointer-events-none" />
                </div>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>

        <FormField
          control={form.control}
          name={`education.${index}.in_progress`}
          render={({ field }) => (
            <FormItem className="flex flex-row items-start space-x-3 space-y-0">
              <FormControl>
                <Checkbox
                  checked={field.value}
                  onCheckedChange={field.onChange}
                />
              </FormControl>
              <FormLabel>In Progress</FormLabel>
            </FormItem>
          )}
        />
      </div>
    </div>
  );
};
