import { formatDistanceStrict, format, parse } from "date-fns";
import { Badge, BadgeProps } from "@/components/ui/badge";
import {
  ExpireState,
  getExpireState,
} from "@/features/documents/utils/documentExpiry";
import { useState } from "react";

export const ExpirationBadgeTime = ({ expiresAt }: { expiresAt: string }) => {
  const [showFullDate, setShowFullDate] = useState(false);
  const expiresAtDate = parse(expiresAt, "yyyy-MM-dd", new Date());
  const expireState = getExpireState(expiresAtDate);

  let badgeColor: BadgeProps["variant"] = "outline";
  let size: BadgeProps["size"] = "light";
  if (expireState === ExpireState.EXPIRING) {
    badgeColor = "destructive";
    size = "default";
  } else if (expireState === ExpireState.WARNING) {
    badgeColor = "warning";
    size = "default";
  }

  const toggleDateFormat = () => {
    setShowFullDate(!showFullDate);
  };

  const dateText = showFullDate
    ? format(expiresAtDate, "MMM d, yyyy")
    : formatDistanceStrict(expiresAtDate, new Date(), {
        addSuffix: true,
      });

  return (
    <button
      onClick={toggleDateFormat}
      aria-label={`Toggle date format. Current format: ${showFullDate ? "full date" : "relative time"}`}
    >
      <Badge
        className="whitespace-nowrap justify-center"
        variant={badgeColor}
        size={size}
      >
        {expiresAtDate < new Date() ? "Expired" : "Expires"} {dateText}
      </Badge>
    </button>
  );
};
