import "@/common/utils/initSentry";
import ReactDOM from "react-dom/client";
import {
  RouterProvider,
} from "@tanstack/react-router";
import "./index.css";

// Import the generated route tree
import { initializeApp } from "./common/utils/liveReload";
import { router } from "./router";

initializeApp();



// Render the app
const rootElement = document.getElementById("root")!;
if (!rootElement.innerHTML) {
  const root = ReactDOM.createRoot(rootElement);
  root.render(<RouterProvider router={router} />);
}
