import { createFileRoute } from "@tanstack/react-router";
import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import {
  DollarSign,
  ShieldCheck,
  Clock,
  Banknote,
  ShieldIcon,
  ArrowRight,
  Building2,
  AlertCircle,
  ExternalLink,
  CheckCircle2,
  Settings,
} from "lucide-react";
import { useProviderUser } from "@/hooks/useProviderUser";
import { createBillingSession } from "@/ajax/billing/createBillingSession";
import { loadConnectAndInitialize } from "@stripe/connect-js";
import {
  ConnectPayouts,
  ConnectComponentsProvider,
  ConnectAccountManagement,
  ConnectAccountOnboarding,
} from "@stripe/react-connect-js";
import { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { createBillingAccount } from "@/ajax/billing/createBillingAccount";
import { PROVIDER_USER_QUERY_KEY } from "@/ajax/queries";
import { queryClient } from "@/ajax/queryClient";

const BillingPage = () => {
  const providerUser = useProviderUser({ staleTime: undefined });

  const [stripeConnectInstance] = useState(() => {
    const fetchClientSecret = async () => {
      // Fetch the AccountSession client secret
      const { clientSecret } = await createBillingSession();
      return clientSecret;
    };
    return loadConnectAndInitialize({
      // This is your test publishable API key.
      publishableKey: import.meta.env.VITE_PUBLIC_STRIPE_PUBLISHABLE_KEY,
      fetchClientSecret: fetchClientSecret,
      appearance: {
        overlays: "dialog",
        variables: {
          colorPrimary: "#625afa",
        },
      },
    });
  });

  const handleAccountSetup = async () => {
    const response = await createBillingAccount();
    queryClient.invalidateQueries({ queryKey: [PROVIDER_USER_QUERY_KEY] });
    console.log("Created stripe account", response);
  };

  const handleOnboardingComplete = async () => {
    queryClient.invalidateQueries({ queryKey: [PROVIDER_USER_QUERY_KEY] });
    console.log("Onboarding complete");
  };

  if (!providerUser.billing.has_account) {
    return (
      <div className="container max-w-4xl mx-auto py-8 px-4">
        <div className="space-y-8">
          <div className="text-center space-y-4">
            <h1 className="text-3xl font-bold">Set Up Your Payment Account</h1>
            <p className="text-lg max-w-2xl mx-auto">
              Before you can start receiving payments for your work, we need to
              set up your Stripe account. This ensures secure and timely
              payments directly to your bank account.
            </p>
          </div>

          <div className="grid gap-6 md:grid-cols-3">
            <Card className="hover:shadow-lg transition-shadow duration-200">
              <CardHeader className="text-center space-y-4">
                <div className="flex justify-center">
                  <div className="p-3 bg-primary/10 rounded-full">
                    <DollarSign className="w-6 h-6 text-primary" />
                  </div>
                </div>
                <CardTitle className="text-xl">
                  Seamless Direct Deposits
                </CardTitle>
                <CardDescription className="text-sm">
                  Receive automatic payments directly to your bank account after
                  completing shifts, with no additional fees
                </CardDescription>
              </CardHeader>
            </Card>

            <Card className="hover:shadow-lg transition-shadow duration-200">
              <CardHeader className="text-center space-y-4">
                <div className="flex justify-center">
                  <div className="p-3 bg-primary/10 rounded-full">
                    <ShieldCheck className="w-6 h-6 text-primary" />
                  </div>
                </div>
                <CardTitle className="text-xl">Bank-Level Security</CardTitle>
                <CardDescription className="text-sm">
                  Your financial information is protected with industry-leading
                  encryption and security protocols via Stripe
                </CardDescription>
              </CardHeader>
            </Card>

            <Card className="hover:shadow-lg transition-shadow duration-200">
              <CardHeader className="text-center space-y-4">
                <div className="flex justify-center">
                  <div className="p-3 bg-primary/10 rounded-full">
                    <Clock className="w-6 h-6 text-primary" />
                  </div>
                </div>
                <CardTitle className="text-xl">Rapid Processing</CardTitle>
                <CardDescription className="text-sm">
                  Quick and reliable payment processing ensures funds reach your
                  account within 2-3 business days
                </CardDescription>
              </CardHeader>
            </Card>
          </div>

          <Card className="mt-8">
            <CardContent className="pt-6">
              <div className="text-center space-y-6">
                <div className="space-y-2">
                  <h3 className="text-lg font-semibold">
                    Ready to Get Started?
                  </h3>
                  <p>
                    To complete your payment account setup, please have the
                    following information ready:
                  </p>
                </div>

                <div className="max-w-md mx-auto bg-muted/30 rounded-lg p-4">
                  <ul className="space-y-3 text-sm">
                    <li className="flex items-center gap-2">
                      <Building2 className="w-4 h-4 text-primary" />
                      <span>Bank account and routing numbers</span>
                    </li>
                    <li className="flex items-center gap-2">
                      <ShieldIcon className="w-4 h-4 text-primary" />
                      <span>
                        Social security number for identity verification
                      </span>
                    </li>
                  </ul>
                </div>

                <Button
                  onClick={handleAccountSetup}
                  size="lg"
                  className="mt-2 px-8"
                  variant="default"
                >
                  Complete Account Setup
                  <ArrowRight className="ml-2 w-4 h-4" />
                </Button>
              </div>
            </CardContent>
          </Card>
        </div>
      </div>
    );
  }

  if (
    providerUser.billing.has_account &&
    !providerUser.billing.details_complete
  ) {
    return (
      <div className="max-w-4xl mx-auto py-8 px-4">
        <Card>
          <CardContent className="pt-6">
            <div className="text-center space-y-6">
              <ConnectComponentsProvider
                connectInstance={stripeConnectInstance}
              >
                <ConnectAccountOnboarding onExit={handleOnboardingComplete} />
              </ConnectComponentsProvider>
            </div>
          </CardContent>
        </Card>
      </div>
    );
  }

  return (
    <ConnectComponentsProvider connectInstance={stripeConnectInstance}>
      <div className="container max-w-4xl mx-auto py-8">
        <Card className="shadow-sm">
          <CardHeader>
            <div className="flex items-center justify-between">
              <div>
                <CardTitle className="text-2xl font-semibold">
                  Payment Dashboard
                </CardTitle>
                <CardDescription>
                  Manage your payouts and account information
                </CardDescription>
              </div>
              <Dialog>
                <DialogTrigger asChild>
                  <Button variant="outline" className="flex items-center gap-2">
                    <Settings className="w-4 h-4" />
                    Account Settings
                  </Button>
                </DialogTrigger>
                <DialogContent className="max-w-5xl w-full">
                  <DialogHeader>
                    <DialogTitle className="text-2xl font-semibold">
                      Account Management
                    </DialogTitle>
                  </DialogHeader>
                  <ConnectAccountManagement />
                </DialogContent>
              </Dialog>
            </div>
          </CardHeader>
          <CardContent>
            <ConnectPayouts />
          </CardContent>
        </Card>
      </div>
    </ConnectComponentsProvider>
  );
};

export const Route = createFileRoute("/_provider/provider/billing/")({
  component: BillingPage,
});
