import { format } from "date-fns";
import { z } from "zod";
import { DEAFields } from "./DEAFields";
import { CDSFields } from "./CDSFields";
import { BLSACLSFields } from "./BLSACLSFields";

export type CertificationType = "bls" | "acls" | "cds" | "dea";

export const CERTIFICATION_TYPE_MAP: {
  [key in CertificationType]: {
    label: string;
    fields: any;
    schema: z.ZodObject<any, any, any, any>;
    createNew: () => any;
    formatValue: (cert: any) => any;
    parseValue: (value: any) => any;
    selectLabel: string;
  };
} = {
  bls: {
    label: "BLS",
    fields: BLSACLSFields,
    schema: z.object({
      type: z.literal("bls"),
      issue_date: z.date(),
      expiration_date: z.date(),
      ecard_code: z.string().min(1, "eCard code is required").max(255),
      delete: z.boolean().optional(),
    }),
    createNew: () => ({
      type: "bls" as const,
      issue_date: new Date(),
      expiration_date: new Date(),
      ecard_code: "",
    }),
    formatValue: (cert: any) => ({
      issue_date: format(cert.issue_date, "yyyy-MM-dd"),
      expiration_date: format(cert.expiration_date, "yyyy-MM-dd"),
      ecard_code: cert.ecard_code,
    }),
    parseValue: (value: any) => ({
      type: "bls" as const,
      issue_date: new Date(value.issue_date),
      expiration_date: new Date(value.expiration_date),
      ecard_code: value.ecard_code,
    }),
    selectLabel: "BLS Certification",
  },
  acls: {
    label: "ACLS",
    fields: BLSACLSFields,
    schema: z.object({
      type: z.literal("acls"),
      issue_date: z.date(),
      expiration_date: z.date(),
      ecard_code: z.string().min(1, "eCard code is required").max(255),
      delete: z.boolean().optional(),
    }),
    createNew: () => ({
      type: "acls" as const,
      issue_date: new Date(),
      expiration_date: new Date(),
      ecard_code: "",
    }),
    formatValue: (cert: any) => ({
      issue_date: format(cert.issue_date, "yyyy-MM-dd"),
      expiration_date: format(cert.expiration_date, "yyyy-MM-dd"),
      ecard_code: cert.ecard_code,
    }),
    parseValue: (value: any) => ({
      type: "acls" as const,
      issue_date: new Date(value.issue_date),
      expiration_date: new Date(value.expiration_date),
      ecard_code: value.ecard_code,
    }),
    selectLabel: "ACLS Certification",
  },
  cds: {
    label: "CDS",
    fields: CDSFields,
    schema: z.object({
      type: z.literal("cds"),
      state_code: z.string().min(1, "State code is required").max(2),
      number: z.string().min(1, "CDS number is required").max(255),
      details: z.string().max(1000).optional(),
      issue_date: z.date().optional().nullable(),
      expiration_date: z.date(),
      delete: z.boolean().optional(),
    }),
    createNew: () => ({
      type: "cds" as const,
      state_code: "",
      number: "",
      details: "",
      issue_date: new Date(),
      expiration_date: new Date(),
    }),
    formatValue: (cert: any) => ({
      state_code: cert.state_code,
      number: cert.number,
      details: cert.details,
      issue_date: cert.issue_date
        ? format(cert.issue_date, "yyyy-MM-dd")
        : null,
      expiration_date: format(cert.expiration_date, "yyyy-MM-dd"),
    }),
    parseValue: (value: any) => ({
      type: "cds" as const,
      state_code: value.state_code,
      number: value.number,
      details: value.details,
      issue_date: value.issue_date ? new Date(value.issue_date) : null,
      expiration_date: new Date(value.expiration_date),
    }),
    selectLabel: "CDS Certification",
  },
  dea: {
    label: "DEA",
    fields: DEAFields,
    schema: z.object({
      type: z.literal("dea"),
      number: z.string().min(1, "DEA number is required").max(255),
      business_activity: z.string().max(255).optional(),
      schedules: z.string().max(255).optional(),
      expiration_date: z.date(),
      delete: z.boolean().optional(),
    }),
    createNew: () => ({
      type: "dea" as const,
      number: "",
      business_activity: "",
      schedules: "",
      expiration_date: new Date(),
    }),
    formatValue: (cert: any) => ({
      number: cert.number,
      business_activity: cert.business_activity,
      schedules: cert.schedules,
      expiration_date: format(cert.expiration_date, "yyyy-MM-dd"),
    }),
    parseValue: (value: any) => ({
      type: "dea" as const,
      number: value.number,
      business_activity: value.business_activity,
      schedules: value.schedules,
      expiration_date: new Date(value.expiration_date),
    }),
    selectLabel: "DEA Certification",
  },
};

export const formSchema = z.object({
  certifications: z.array(
    z.discriminatedUnion("type", [
      CERTIFICATION_TYPE_MAP.bls.schema,
      CERTIFICATION_TYPE_MAP.acls.schema,
      CERTIFICATION_TYPE_MAP.cds.schema,
      CERTIFICATION_TYPE_MAP.dea.schema,
    ]),
  ),
});
