import { supabase } from "@/ajax/clients/supabase";
import { Database } from "@/common/types/database.types";
import { PostgrestError } from "@supabase/supabase-js";

export const callSupabaseRpc = <
  FnName extends keyof Database["public"]["Functions"],
  Args extends Database["public"]["Functions"][FnName]["Args"],
>({
  fnName,
  args,
}: {
  fnName: FnName;
  args?: Args;
}) => {
  return supabase.rpc(fnName, args);
};

export const callSupabaseFnFromClient = async <RT>(
  params: Parameters<typeof callSupabaseRpc>[0],
): Promise<RT> => {
  const { error, data } = await callSupabaseRpc(params).returns<RT>();

  if (error) {
    console.error("Error:", error);
    throw error;
  }

  return data;
};

export const callSupabaseFnFromClientNoThrow = async <RT>(
  params: Parameters<typeof callSupabaseRpc>[0],
): Promise<{data: RT | null, error: PostgrestError | null}> => {
  const { error, data } = await callSupabaseRpc(params).returns<RT>();


  return {data, error};
};
