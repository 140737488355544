import axios from "axios";
import { PhoneValidateAPI } from "@/common/types/api/PhoneValidateAPI";
import { API_HOST } from "../../consts/api_host";
import { getAccessToken } from "../clients/supabase";

export const validatePhoneNumber = async (
  body: PhoneValidateAPI["body"],
): Promise<PhoneValidateAPI["return"]> => {
  try {
    const response = await axios.post<PhoneValidateAPI["return"]>(
      `${API_HOST}/phone/validate`,
      body,
      {
        headers: {
          Authorization: `Bearer ${await getAccessToken()}`,
        },
      },
    );
    return response.data;
  } catch (e) {
    console.error(e);
    return {
      valid: false,
      errors: ["Unable to complete request"],
    };
  }
};
