import NotificationSettingsForm from "@/components/settings/NotificationSettingsForm";
import { createFileRoute } from "@tanstack/react-router";
import { queryClient } from "../../../../ajax/queryClient";
import { communicationPreferencesQueryOptions } from "../../../../ajax/queries";
import { DeleteAccount } from "@/features/settings/components/DeleteAccount";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { EditContactInfo } from "@/features/settings/components/EditContactInfo";

export const Route = createFileRoute("/_provider/provider/settings/")({
  component: SettingsPage,
  loader: () =>
    queryClient.ensureQueryData(communicationPreferencesQueryOptions()),
});

export default function SettingsPage() {
  return (
    <div className="p-2 pt-4 md:pt-8 md:p-8 flex justify-center">
      <div className="space-y-8 max-w-3xl w-full">
        <h1 className="text-2xl font-bold mb-6 text-center">
          Account Settings
        </h1>
        <NotificationSettingsForm />
        <Card className="border-destructive/20">
          <CardHeader>
            <CardTitle className="text-destructive">Delete Account</CardTitle>
            <CardDescription>Permanently delete your account</CardDescription>
          </CardHeader>
          <CardContent>
            <DeleteAccount />
          </CardContent>
        </Card>
      </div>
    </div>
  );
}
