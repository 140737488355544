import { callSupabaseFnFromClient } from "../utils/callSupabaseFnFromClient";

export const updateDeviceToken = async ({
  deviceToken,
  platformId,
  platformVersion,
  deviceId,
}: {
  deviceToken: string;
  platformId: number;
  platformVersion: string;
  deviceId: string;
}): Promise<{ id: string }[]> => {
  return await callSupabaseFnFromClient({
    fnName: "update_device_token",
    args: {
      device_token: deviceToken,
      device_id: deviceId,
      platform_id: platformId,
      platform_version: platformVersion,
    },
  });
};
