import { supabase } from "../clients/supabase";

export async function updateOnboardingAccountDetails({
  firstName,
  lastName,
  npiNumber,
  healthcareProfessionalTypeId,
  specialtyCode,
}: {
  firstName: string;
  lastName: string;
  npiNumber: string;
  healthcareProfessionalTypeId: string;
  specialtyCode: string;
}): Promise<{ error: any }> {
  // This method doesn't return anything, so we don't need data
  const { error } = await supabase.rpc("update_onboarding_account_details", {
    first_name: firstName,
    last_name: lastName,
    npi_number: npiNumber,
    healthcare_professional_type_id: healthcareProfessionalTypeId,
    specialty_code: specialtyCode,
  });

  return {
    error,
  };
}
