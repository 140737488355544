import Select from "react-select";
import { useSuspenseQuery } from "@tanstack/react-query";
import { getSpecialtiesQueryOptions } from "@/ajax/queries";
import { useMemo } from "react";

interface SpecialtiesSelectProps {
  value?: string;
  onChange?: (value: string) => void;
  placeholder?: string;
  disabled?: boolean;
  useParent?: boolean;
}

export const SpecialtiesSelect = ({
  value,
  onChange,
  placeholder = "Select specialty",
  disabled = false,
  useParent = false,
}: SpecialtiesSelectProps) => {
  const { data: specialties } = useSuspenseQuery(getSpecialtiesQueryOptions());

  const specialtyOptions = useMemo(() => {
    return specialties
      .filter((specialty) => (useParent ? specialty.is_parent : true))
      .map((specialty) => ({
        label: specialty.name,
        value: specialty.code,
      }));
  }, [specialties]);

  const selectedOption = useMemo(() => {
    return specialtyOptions.find((option) => option.value === value);
  }, [value, specialtyOptions]);

  return (
    <Select
      value={selectedOption}
      onChange={(option) => onChange?.(option?.value || "")}
      options={specialtyOptions}
      isDisabled={disabled}
      placeholder={placeholder}
      isClearable
      classNames={{
        control: (state) => "py-2",
      }}
    />
  );
};
