import * as React from "react";
import { Download, Upload } from "lucide-react";

import { Button } from "@/components/ui/button";

import { useSuspenseQuery } from "@tanstack/react-query";
import { createFileRoute } from "@tanstack/react-router";
import { z } from "zod";
import { queryClient } from "@/ajax/queryClient";
import { filesQueryOptions, providerUserQueryOptions } from "@/ajax/queries";
import { downloadAllFilesZip } from "@/ajax/documents/downloadAllFilesZip";
import Loading from "@/components/Loading";
import { Documents } from "@/components/Documents/Documents";
import { SharingProfileButton } from "@/features/profile/components/SharingProfileButton";
import { useProviderUser } from "@/hooks/useProviderUser";

export const Route = createFileRoute("/_provider/provider/documents/")({
  component: DocumentsPage,
  validateSearch: z.object({
    fileId: z.string().optional(),
  }),
  loader: () =>
    Promise.all([
      queryClient.ensureQueryData(filesQueryOptions()),
      queryClient.ensureQueryData(providerUserQueryOptions()),
    ]),
});

function DocumentsPage() {
  const [showUploadSection, setShowUploadSection] = React.useState(false);

  const filesQueryState = useSuspenseQuery(filesQueryOptions());

  const providerUserQueryState = useSuspenseQuery(providerUserQueryOptions());
  const providerUser = providerUserQueryState.data;

  const [downloadLoading, setDownloadLoading] = React.useState(false);

  const handleDownloadAll = async () => {
    setDownloadLoading(true);
    try {
      await downloadAllFilesZip({
        userId: providerUser.id,
        files: filesQueryState.data,
        userName: [providerUser.first_name, providerUser.last_name].filter(Boolean).join(" "),
      });
    } finally {
      setDownloadLoading(false);
    }
  };

  return (
    <div className="w-full p-2 pt-4 md:pt-4 md:p-4 min-h-screen">
      {/* <div> */}
      <div className="max-w-3xl mx-auto flex justify-between items-center mb-8 px-4 md:px-0">
        <h2 className="text-title-md2 font-bold text-black dark:text-white">
          Documents
        </h2>
        <div className="flex gap-2 flex-col sm:flex-row">
          {!showUploadSection && (
            <Button
              variant="outline"
              onClick={() => setShowUploadSection(!showUploadSection)}
            >
              <Upload className="mr-2" />
              Upload Files
            </Button>
          )}
          <Button
            disabled={downloadLoading}
            variant="outline"
            onClick={handleDownloadAll}
          >
            {downloadLoading ? (
              <>
                <Loading className="w-6 h-6 mr-2" /> Generating zip...
              </>
            ) : (
              <>
                <Download className="mr-2" />
                Download All Files
              </>
            )}
          </Button>
          {/* <SharingProfileButton /> */}
        </div>
      </div>
      <div className="max-w-3xl mx-auto p-4 rounded-md border bg-white animate-fade-in-delay-2 overflow-x-auto">
        <Documents
          showErrorOnMissingDocuments={true}
          providerUser={providerUser}
          showUpload={showUploadSection}
        />
      </div>
    </div>
  );
}
