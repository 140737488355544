import { updateProviderOnboardingStep } from "@/ajax/onboarding/updateProviderOnboardingStep";
import { PushNotificationsForm } from "@/components/Forms/PushNotificationsForm";
import { ProviderOnboardingStep } from "../enums/ProviderOnboardingStep";

export default function PushNotificationsOnboarding({
  onComplete,
}: {
  onComplete: () => void;
}) {
  const handleNext = async () => {
    await updateProviderOnboardingStep(
      ProviderOnboardingStep.PUSH_NOTIFICATIONS,
    );
    onComplete();
  };

  return (
    <div>
      <h2 className="text-2xl font-bold text-gray-900 mb-1 animate-fade-in">
        Enable Notifications
      </h2>
      <PushNotificationsForm onComplete={handleNext} />
    </div>
  );
}
