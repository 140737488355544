import { Dashboard } from "@uppy/react";
import React, { useEffect, useRef, useState } from "react";
import Uppy from "@uppy/core";
import Tus from "@uppy/tus";
import "@uppy/core/dist/style.css";
import "@uppy/dashboard/dist/style.css";
import { createFiles } from "@/ajax/documents/createFiles";
import { processFiles } from "@/ajax/documents/processFiles";
import Loading from "./Loading";
import { AlertCircle, Upload } from "lucide-react";
import { Alert, AlertDescription, AlertTitle } from "./ui/alert";
import { supabase } from "@/ajax/clients/supabase";
import { Button } from "./ui/button";
import { captureException } from "@sentry/browser";
import { getEnvVar } from "@/common/utils/environment";

const UPLOAD_URL = `${getEnvVar("VITE_PUBLIC_SUPABASE_URL")}/storage/v1/upload/resumable`;
const UPLOAD_BUCKET_NAME = "credentials";

export const UploadDocuments = ({
  onFilesUploaded,
  minDocuments = Number(
    getEnvVar("VITE_PUBLIC_MIN_CREDENTIAL_DOCUMENT_UPLOAD") ?? 10,
  ),
  onFilesToUploadCountChange,
  user,
  height = 200,
  autoUpload = false,
}: {
  onFilesToUploadCountChange?: (count: number) => void;
  minDocuments?: number;
  autoUpload?: boolean;
  user: {
    id: string;
  };
  height?: number;
  onFilesUploaded: () => void;
}) => {
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [failedFiles, setFailedFiles] = useState<
    Array<{ id: string; name: string; fileId: string }>
  >([]);
  const fileToFileId = useRef<Record<string, string>>({});
  const [uppy, setUppy] = useState<Uppy | null>(null);

  useEffect(() => {
    const initUppy = async () => {
      const uppyInstance = new Uppy({
        restrictions: {
          maxNumberOfFiles: 100,
          maxFileSize: 5 * 1024 * 1024,
          allowedFileTypes: [".pdf", ".jpg", ".jpeg", ".png", ".heic"],
        },
      }).use(Tus, {
        endpoint: UPLOAD_URL,
        storeFingerprintForResuming: false,
        fingerprint: async (file, options) => {
          return Math.random().toString(36);
        },
        removeFingerprintOnSuccess: true,
        chunkSize: 1024 * 1024,
        allowedMetaFields: [
          "bucketName",
          "objectName",
          "contentType",
          "cacheControl",
        ],
      });
      uppyInstance.addPreProcessor(async (file) => {
        // This is super hacky. Ideally Tus would have a headers function that could return a promise (it'd current sync). There is no API at this time to async update the headers.
        // This is done on every file, but probably only needs to be done once per batch. It works for now, but is a hack and should be fixed if Tus is updated.
        const { data, error } = await supabase.auth.refreshSession();
        uppyInstance.getPlugin("Tus")?.setOptions({
          headers: {
            authorization: `Bearer ${data.session?.access_token}`,
          },
        });
      });

      setUppy(uppyInstance);
    };

    initUppy();
  }, []);

  useEffect(() => {
    if (!uppy) return;
    uppy.opts.onBeforeUpload = (files) => {
      if (!user) return false;

      console.log("files", fileToFileId.current, files, user.id);
      return Object.fromEntries(
        Object.entries(files).map(
          ([fileId, file]: [fileId: string, file: any]) => {
            const newFileUUID = fileToFileId.current[file.name ?? ""];
            const path = `${user.id}/${newFileUUID}`;
            console.log("path", path);

            file.meta = {
              ...file.meta,
              bucketName: UPLOAD_BUCKET_NAME,
              objectName: path,
              contentType: file.type,
              fileId: newFileUUID,
            };
            file.id = `${file.id}_${newFileUUID}`;

            return [file.id, file];
          },
        ),
      );
    };

    // @ts-ignore complete without arguments
    uppy.off("complete");
    uppy.on("complete", async (result) => {
      console.log("successful files:", result.successful);
      if (result.failed && result.failed.length > 0) {
        console.error("failed files:", result.failed);
      }

      if (result.failed && result.failed.length > 0) {
        captureException(new Error("Uppy upload failed"), {
          extra: {
            failedFiles: result.failed.map((f) => ({
              name: f.name,
              error: f.error,
              id: f.id,
            })),
            userId: user.id,
          },
        });
      }

      if (!result.successful || !result.successful.length) {
        setLoading(false);
        return;
      }

      const successfulFiles = result.successful.map((file) => ({
        id: file.id,
        name: file.name ?? "",
        fileId: file.meta.fileId as string,
      }));

      // On success, remove the files and notify
      successfulFiles.forEach((file) => {
        uppy.removeFile(file.id);
      });

      attemptProcessFiles(successfulFiles);
    });

    // @ts-ignore error without arguments
    uppy.off("error");
    uppy.on("error", (error) => {
      console.error("uppy error", error);
      captureException(error, {
        extra: {
          userId: user.id,
          files: uppy.getFiles().map((f) => ({
            name: f.name,
            id: f.id,
            size: f.size,
          })),
        },
      });
    });

    const onFilesChanged = () => {
      const fileCount = uppy.getFiles().length;
      onFilesToUploadCountChange?.(fileCount);
    };

    uppy.on("files-added", () => {
      onFilesChanged();
      if (autoUpload) {
        onUpload();
      }
    });
    uppy.on("file-removed", onFilesChanged);
  }, [uppy, user]);

  const onUpload = async () => {
    if (!uppy) return;

    const files = uppy.getFiles();
    if (files.length < minDocuments) {
      return;
    }

    if (files.length > 80) {
      setError("We do not support uploading more than 80 files.");
      return;
    }
    setLoading(true);
    setError(null);

    const { data, error } = await createFiles({
      fileNames: files.map((file) => file.name ?? ""),
    });

    if (!data) {
      captureException(error, {
        extra: {
          userId: user.id,
          fileCount: files.length,
        },
      });
      setLoading(false);
      return;
    }

    fileToFileId.current = Object.fromEntries(
      data.map((file: any) => [file.file_name, file.id]),
    );
    uppy.upload();
  };

  const attemptProcessFiles = async (
    successfulFiles: { id: string; name: string; fileId: string }[],
  ) => {
    try {
      const successfulFileIds = successfulFiles.map(
        (file) => file.fileId as string,
      );
      await processFiles({
        fileIds: successfulFileIds,
      });

      setFailedFiles([]); // Clear any failed files on success
      onFilesUploaded();
    } catch (err) {
      console.error("Failed to process files:", err);
      setError(`Some files failed to process. You can retry or remove them.`);
      setFailedFiles(successfulFiles);
      captureException(err, {
        extra: {
          userId: user.id,
          fileCount: successfulFiles.length,
        },
      });
    }

    setLoading(false);
  };

  const retryFailedFiles = () => {
    setError(null);
    attemptProcessFiles(failedFiles);
    setFailedFiles([]);
  };

  const removeFailedFiles = () => {
    setFailedFiles([]);
    setError(null);
  };

  return (
    <div>
      {uppy && (
        <>
          <div>
            <Dashboard
              proudlyDisplayPoweredByUppy={false}
              hideUploadButton={true}
              width="100%"
              singleFileFullScreen={false}
              height={height}
              className="bg-red-400"
              fileManagerSelectionType="both"
              uppy={uppy}
              note="Upload PDF, JPG, JPEG, PNG, or HEIC files"
            />
          </div>
        </>
      )}
      {error && (
        <div className="my-2">
          <Alert variant="destructive">
            <AlertCircle className="h-4 w-4" />
            <AlertTitle>Error</AlertTitle>
            <AlertDescription>
              {error}
              {failedFiles.length > 0 && (
                <div className="mt-2 flex gap-2">
                  <Button
                    type="button"
                    variant="outline"
                    size="sm"
                    onClick={retryFailedFiles}
                    disabled={loading}
                  >
                    Retry Failed Files
                  </Button>
                  <Button
                    type="button"
                    variant="outline"
                    size="sm"
                    onClick={removeFailedFiles}
                  >
                    Remove Failed Files
                  </Button>
                </div>
              )}
            </AlertDescription>
          </Alert>
        </div>
      )}
      {!autoUpload && (
        <Button
          type="button"
          size="none"
          className="p-3 w-full mb-4"
          onClick={onUpload}
          disabled={loading}
        >
          {loading ? <Loading className="h-5 w-5" /> : "Upload"}
        </Button>
      )}
    </div>
  );
};
