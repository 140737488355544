import { ZipWriter, BlobWriter, Uint8ArrayReader } from "@zip.js/zip.js";
import { supabase } from "../clients/supabase";
import { FileType } from "./getFiles";
import { generateDocumentName } from "@/features/documents/utils/generateDocumentName";
import { Filesystem, Directory } from '@capacitor/filesystem';
import { FileOpener } from '@capacitor-community/file-opener';
import { Capacitor } from '@capacitor/core';

export async function downloadAllFilesZip({
  userId,
  userName,
  files,
  batchSize = 10,
}: {
  userId: string;
  userName: string;
  files: FileType[];
  batchSize?: number;
}) {
  const zipFileWriter = new BlobWriter("application/zip");
  const zipWriter = new ZipWriter(zipFileWriter);

  try {
    // Group files by type_name
    const filesByType: { [key: string]: FileType[] } = {};
    files.forEach(file => {
      const typeName = file.type_name || "Other";
      if (!filesByType[typeName]) {
        filesByType[typeName] = [];
      }
      filesByType[typeName].push(file);
    });

    // Process each type folder
    for (const typeName in filesByType) {
      const typeFiles = filesByType[typeName];
      
      // Process files in batches within each type folder
      for (let i = 0; i < typeFiles.length; i += batchSize) {
        const batch = typeFiles.slice(i, i + batchSize);

        // Download files in parallel
        const downloadPromises = batch.map(async (file: FileType) => {
          const { data, error } = await supabase.storage
            .from("credentials")
            .download(`${userId}/${file.id}`);

          if (error) {
            throw error;
          }

          const fileName = generateDocumentName(file);
          return { name: fileName, data, typeName };
        });

        // Wait for all downloads in the current batch to complete
        const downloadedFiles = await Promise.all(downloadPromises);

        // Add downloaded files to the zip in their type folders
        for (const file of downloadedFiles) {
          const folderPath = `${file.typeName}/${file.name}`;
          await zipWriter.add(
            folderPath,
            new Uint8ArrayReader(new Uint8Array(await file.data.arrayBuffer())),
          );
        }
      }
    }

    // Close the zip writer and get the zip file as a blob
    const zipBlob = await zipWriter.close();
    const zipFileName = `${userName}_Saile_Credentials_Documents.zip`;

    if (Capacitor.isNativePlatform()) {
      // Convert Blob to base64 string
      const base64Data = await new Promise<string>((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(zipBlob);
        reader.onloadend = () => {
          const base64String = reader.result as string;
          resolve(base64String.split(',')[1]);
        };
      });

      await Filesystem.requestPermissions();

      // Write to Documents directory for iOS
      const result = await Filesystem.writeFile({
        path: zipFileName,
        data: base64Data,
        directory: Directory.Documents,
        recursive: true
      });

      await FileOpener.open({
        filePath: result.uri,
        openWithDefault: true,
      });
    } else {
      // Web download
      const downloadLink = document.createElement("a");
      downloadLink.href = URL.createObjectURL(zipBlob);
      downloadLink.download = zipFileName;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    }
  } catch (error) {
    console.error("Error creating zip file:", error);
  }
}
