import { createFileRoute, Outlet, redirect } from "@tanstack/react-router";
import { useEffect } from "react";
import { supabase } from "@/ajax/clients/supabase";
import { AdminLayout } from "../components/Layouts/AdminLayout";

export const Route = createFileRoute("/_admin")({
  beforeLoad: async ({ location, context }) => {
    if (!context.user || !context.user.is_admin) {
      throw redirect({
        to: "/auth-org/signin",
        search: {
          // Use the current location to power a redirect after login
          // (Do not use `router.state.resolvedLocation` as it can
          // potentially lag behind the actual current location)
          redirect: location.href,
        },
      });
    }

    return {
      user: context.user,
    };
  },
  component: () => {
    const { user } = Route.useRouteContext();

    useEffect(() => {
      const {
        data: { subscription },
      } = supabase.auth.onAuthStateChange((event, session) => {
        if (event === "SIGNED_OUT") {
          document.location.reload();
        }
      });
      return () => {
        subscription.unsubscribe();
      };
    }, []);

    return (
      <AdminLayout>
        <Outlet />
      </AdminLayout>
    );
  },
});
