import React, {
  useState,
  useEffect,
  useMemo,
  useImperativeHandle,
  forwardRef,
} from "react";
import { UploadDocuments } from "@/components/UploadDocuments";
import { ProviderUser } from "@/common/types/ProviderUser";
import { Alert } from "../ui/alert";
import { Circle, Loader2, File, ExternalLink, FileText } from "lucide-react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { filesQueryOptions } from "@/ajax/queries";
import { DOCUMENT_CATEGORIES } from "@/consts/document_categories";
import { DocumentCategory } from "@/consts/document_categories";
import { DocumentListItem } from "./DocumentListItem";
import FilePreviewModal from "../FilePreview/FilePreviewModal";
import { Button } from "../ui/button";
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "../ui/collapsible";
import { differenceInDays, parse } from "date-fns";
import { ExpirationBadgeTime } from "@/features/documents/components/Badge/ExpirationBadgeTime";
import { useNavigate, useSearch } from "@tanstack/react-router";
import { ExpirationText } from "@/features/documents/components/Badge/ExpirationText";

const sortedDocuments = [...DOCUMENT_CATEGORIES].sort((a, b) =>
  a.optional ? 1 : b.optional ? -1 : a.name.localeCompare(b.name),
);

export const Documents = forwardRef(
  (
    {
      providerUser,
      showErrorOnMissingDocuments = false,
      showUpload = true,
    }: {
      providerUser: ProviderUser;
      showErrorOnMissingDocuments?: boolean;
      showUpload?: boolean;
    },
    ref,
  ) => {
    const [error, setError] = useState<string | null>(null);
    const queryClient = useQueryClient();
    const [isVisible, setIsVisible] = useState(!document.hidden);
    const [isProcessingExpanded, setIsProcessingExpanded] = useState(false);
    const navigate = useNavigate();
    const search = useSearch({
      strict: false,
    });

    const { data: uploadedFiles = [], refetch } = useQuery(filesQueryOptions());

    const selectedFile = useMemo(() => {
      if (!search.fileId) return null;
      return uploadedFiles.find((file) => file.id === search.fileId) ?? null;
    }, [uploadedFiles, search.fileId]);

    const expiringFiles = useMemo(() => {
      return uploadedFiles.filter((file) => {
        if (!file.expires_at) {
          return false;
        }
        const daysUntilExpiry = differenceInDays(
          parse(file.expires_at, "yyyy-MM-dd", new Date()),
          new Date(),
        );
        return daysUntilExpiry <= 60;
      });
    }, [uploadedFiles]);

    const processingFiles = useMemo(
      () => uploadedFiles.filter((file) => !file.type_id),
      [uploadedFiles],
    );
    const completedFiles = useMemo(
      () => uploadedFiles.filter((file) => file.type_id),
      [uploadedFiles],
    );

    // Find files that don't match any required document type
    const otherFiles = useMemo(
      () =>
        completedFiles.filter(
          (file) =>
            !DOCUMENT_CATEGORIES.some((doc: DocumentCategory) =>
              doc.fileTypeIds.some((id: number) => id === file.type_id),
            ),
        ),
      [completedFiles],
    );

    const { missingDocuments, uploadedDocumentTypes } = useMemo(() => {
      const missing: DocumentCategory[] = [];
      const uploaded: DocumentCategory[] = [];

      for (const doc of sortedDocuments) {
        let isUploaded = false;
        for (const typeId of doc.fileTypeIds) {
          if (completedFiles.some((file) => file.type_id === typeId)) {
            isUploaded = true;
            break;
          }
        }
        if (isUploaded) {
          uploaded.push(doc);
        } else {
          missing.push(doc);
        }
      }

      return {
        missingDocuments: missing,
        uploadedDocumentTypes: uploaded,
      };
    }, [completedFiles, sortedDocuments]);

    useImperativeHandle(ref, () => ({
      getMissingDocuments: () =>
        missingDocuments.filter((doc) => !doc.optional),
    }));

    useEffect(() => {
      const handleVisibilityChange = () => {
        setIsVisible(!document.hidden);
      };

      document.addEventListener("visibilitychange", handleVisibilityChange);

      return () => {
        document.removeEventListener(
          "visibilitychange",
          handleVisibilityChange,
        );
      };
    }, []);

    useEffect(() => {
      let interval: number | null = null;
      const FIVE_MINUTES = 5 * 60 * 1000;

      if (isVisible && processingFiles.length > 0) {
        let startTime = Date.now();
        interval = window.setInterval(() => {
          if (Date.now() - startTime >= FIVE_MINUTES) {
            if (interval !== null) {
              window.clearInterval(interval);
            }
            return;
          }
          refetch();
        }, 5000);
      }

      return () => {
        if (interval !== null) {
          window.clearInterval(interval);
        }
      };
    }, [refetch, isVisible, processingFiles.length]);

    const handleFileUpload = async () => {
      try {
        await queryClient.invalidateQueries({ queryKey: ["files"] });
      } catch (error) {
        setError("Something went wrong. Please try again.");
      }
    };

    const handleEditFile = (fileId: string) => {
      navigate({
        // @ts-ignore
        search: (prev) => ({
          ...prev,
          fileId,
        }),
      });
    };

    return (
      <div>
        {showUpload && (
          <div className="mb-6">
            <UploadDocuments
              minDocuments={1}
              user={providerUser}
              autoUpload={true}
              onFilesUploaded={handleFileUpload}
            />
            {error && <Alert>{error}</Alert>}
          </div>
        )}
        <div>
          {processingFiles.length > 0 && (
            <Collapsible
              open={isProcessingExpanded}
              onOpenChange={setIsProcessingExpanded}
              className="mb-6"
            >
              <div className="border rounded-lg p-3">
                <div>
                  <div className="flex items-center">
                    <Loader2 className="h-4 w-4 animate-spin mr-2" />
                    <span className="font-medium text-lg">
                      Processing {processingFiles.length}{" "}
                      {processingFiles.length === 1 ? "document" : "documents"}
                    </span>
                    <CollapsibleTrigger asChild>
                      <Button
                        variant="ghost"
                        className="ml-2 hover:underline active:underline text-sm text-gray-700"
                        size="sm"
                      >
                        {isProcessingExpanded ? "Hide Details" : "View Details"}
                      </Button>
                    </CollapsibleTrigger>
                  </div>
                </div>
                <CollapsibleContent>
                  <ul className="space-y-2 list-none mt-3 ml-4">
                    {processingFiles.map((file, index) => (
                      <li key={index}>
                        <div className="flex items-center">
                          <File className="mr-1 h-4 w-4" />
                          <span>{file.file_name}</span>
                        </div>
                      </li>
                    ))}
                  </ul>
                </CollapsibleContent>
              </div>
            </Collapsible>
          )}

          {expiringFiles.length > 0 && (
            <div className="mb-6">
              <h3 className="text-lg font-semibold text-gray-800 mb-2 flex items-center">
                Expiring Documents:
              </h3>
              <div className="grid grid-cols-1 gap-1">
                {expiringFiles.map((file, index) => {
                  return (
                    <div key={index} className="w-full bg-white p-2 pt-2 pb-3 border-b flex items-center">
                      <FileText className="h-6 w-6 mr-2 flex-shrink-0 hidden md:block" />
                      <div>
                        <button
                          onClick={() => handleEditFile(file.id)}
                          className="transition-all cursor-pointer w-full text-left"
                          role="button"
                          tabIndex={0}
                        >
                          <div className="font-medium break-words">
                            {file.generated_title ?? file.file_name}
                          </div>
                        </button>
                        <div>
                          <span className="text-sm"><ExpirationText expiresAt={file.expires_at} /></span>{" "}
                          {file.renew_url && (
                            <a
                              href={file.renew_url}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="inline-flex items-center px-3 underline text-sm font-medium"
                            >
                              <ExternalLink className="h-4 w-4 mr-1" />
                              Renew
                            </a>
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}

          <h3 className="text-lg font-semibold text-gray-800 mb-3">
            Required Documents:
          </h3>

          <ul className="space-y-4 list-none ml-2">
            {missingDocuments
              .filter((doc) => !doc.optional)
              .map((doc, index) => (
                <DocumentListItem
                  key={index}
                  icon={
                    showErrorOnMissingDocuments ? (
                      <div className="h-2 w-2 mr-2 rounded-full bg-red-600"></div>
                    ) : (
                      <Circle className="mr-2 h-4 w-4 text-gray-400" />
                    )
                  }
                  title={doc.name}
                  description={doc.description}
                  onEditFile={handleEditFile}
                  showRequiredMessage={true}
                />
              ))}
            {uploadedDocumentTypes
              .filter((doc) => !doc.optional)
              .map((doc, index) => {
                const uploadedDocuments = completedFiles.filter((file) =>
                  doc.fileTypeIds.includes(file.type_id),
                );
                return (
                  <DocumentListItem
                    key={index + missingDocuments.length}
                    icon={
                      <div className="h-2 w-2 mr-2 rounded-full bg-green-500"></div>
                    }
                    title={doc.name}
                    description={doc.description}
                    files={uploadedDocuments}
                    onEditFile={handleEditFile}
                  />
                );
              })}
          </ul>

          <h3 className="text-lg font-semibold text-gray-800 mb-3 mt-6">
            Optional Documents:
          </h3>

          <ul className="space-y-4 list-none ml-2">
            {missingDocuments
              .filter((doc) => doc.optional)
              .map((doc, index) => (
                <DocumentListItem
                  key={index}
                  icon={
                    <div className="h-2 w-2 mr-2 rounded-full bg-gray-400"></div>
                  }
                  title={doc.name}
                  description={doc.description}
                  onEditFile={handleEditFile}
                />
              ))}
            {uploadedDocumentTypes
              .filter((doc) => doc.optional)
              .map((doc, index) => {
                const uploadedDocuments = completedFiles.filter((file) =>
                  doc.fileTypeIds.includes(file.type_id),
                );
                return (
                  <DocumentListItem
                    key={index + missingDocuments.length}
                    icon={
                      <div className="h-2 w-2 mr-2 rounded-full bg-green-500"></div>
                    }
                    title={doc.name}
                    description={doc.description}
                    files={uploadedDocuments}
                    onEditFile={handleEditFile}
                  />
                );
              })}
            {otherFiles.length > 0 && (
              <DocumentListItem
                icon={
                  <div className="h-2 w-2 mr-2 rounded-full bg-green-500"></div>
                }
                title="Other Documents"
                description="These documents don't match any required document type. Please edit them if they are suppose to match a required document type."
                files={otherFiles}
                onEditFile={handleEditFile}
              />
            )}
          </ul>
        </div>
        {selectedFile && (
          <FilePreviewModal
            userId={providerUser.id}
            file={selectedFile}
            onClose={() => {
              navigate({
                // @ts-ignore
                search: (prev) => ({
                  ...prev,
                  fileId: undefined,
                }),
              });
            }}
          />
        )}
      </div>
    );
  },
);
