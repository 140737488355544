import React, { useState, useEffect } from 'react'
import { createFileRoute, useNavigate } from '@tanstack/react-router'
import { Lock } from 'lucide-react'
import { z } from 'zod'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import Loading from '@/components/Loading'
import { signInWithEmailLink } from '@/ajax/auth/signInWithEmailLink'
import {
  initializeGoogleAuth,
  signInWithGoogle,
} from '@/ajax/auth/signInWithGoogle'
import EmailVerification from '@/components/EmailVerification/EmailVerification'
import { Button } from '@/components/ui/button'
import { signInWithPassword } from '@/ajax/auth/signInWithPassword'
import { AuthError, User } from '@supabase/supabase-js'
import { Alert, AlertDescription } from "@/components/ui/alert"
import { getLastEmail, saveLastEmail } from '@/common/utils/lastEmail'

const FormSchema = z.object({
  email: z.string().email('Invalid email address'),
  password: z.string().optional(),
})

type FormValues = z.infer<typeof FormSchema>

const LOGO_PATH = '/static/images/logo.png'

export const Route = createFileRoute('/auth-org/signin/')({
  component: SignInPage,

  validateSearch: z.object({
    l: z.string().optional(),
    redirect: z.string().regex(/^\//).optional()
  }),
})

function SignInPage() {
  const [serverErrors, setServerErrors] = useState<Record<string, string[]>>({})
  const [isSubmitting, setIsSubmitting] = useState(false)

  const [emailSent, setEmailSent] = useState<null | string>(null)
  const [showPassword, setShowPassword] = useState(false)

  const navigate = useNavigate()
  const search = Route.useSearch()

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<FormValues>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      email: getLastEmail() || '',
    },
  })

  const email = watch('email')
  useEffect(() => {
    if (email === 'demo@saileapp.com') {
      setShowPassword(true)
    }
  }, [email])

  const checkConnection = () => {
    if (!navigator.onLine) {
      setServerErrors({
        _all: ['Please check your internet connection and try again'],
      })
      return false
    }
    return true
  }

  const handleGoogle = async () => {
    if (!checkConnection()) {
      return
    }

    const { data, error } = await signInWithGoogle()

    if (error) {
      setServerErrors({
        _all: [error.message],
      })
      return
    }

    if (data && 'url' in data && data.url) {
      navigate({ to: data.url })
    } else {
      navigate({ to: search.redirect || '/org/home' })
    }
  }

  const onSubmit = async (data: FormValues) => {
    if (!checkConnection()) {
      return
    }

    setIsSubmitting(true)
    setServerErrors({})

    let loginError: AuthError | null = null;
    if (showPassword) {
      const { error, data: loginData } = await signInWithPassword({
        email: data.email,
        password: data.password || '',
      });
      loginError = error;
      if (loginData?.user?.email) {
        saveLastEmail(loginData.user.email);
      }
    } else {
      const { error, data: loginData } = await signInWithEmailLink({
        email: data.email,
        shouldCreateUser: true,
      });
      loginError = error;
    }
    if (loginError) {
      setServerErrors({
        _all: [loginError.message],
      })
      setIsSubmitting(false)
      return
    }

    if (showPassword) {
      navigate({ to: search.redirect || '/org/home' })
    } else {
      setEmailSent(data.email)
    }
  }

  const handleComplete = () => {
    navigate({ to: search.redirect || '/org/home' })
  }

  const handleBack = () => {
    setEmailSent(null)
    setIsSubmitting(false)
  }

  const handleResend = async () => {
    if (!checkConnection()) {
      return
    }

    if (!emailSent) {
      console.error('No email to resend')
      return
    }

    const { error, data } = await signInWithEmailLink({
      email: emailSent,
      shouldCreateUser: true,
    });
  }
  useEffect(() => {
    initializeGoogleAuth()
  }, [])

  if (emailSent) {
    return (
      <EmailVerification
        referralCode={search.l}
        onResend={handleResend}
        onBack={handleBack}
        onComplete={handleComplete}
        email={emailSent}
      />
    )
  }

  return (
    <main className="min-h-screen flex bg-gray-50">
      {/* Right Column - Sign Up Form */}
      <div className="w-full flex items-center justify-center p-8">
        <div className="max-w-md w-full">
          <div className="flex flex-col items-center mb-8 text-center">
            <img
              src={LOGO_PATH}
              alt="Saile Company Logo"
              width={180}
              height={72}
              className="mx-auto mb-8"
            />
            {search.redirect ? (
              <Alert variant="default">
                <AlertDescription>
                  Your session or link has expired. Please sign in again to continue.
                </AlertDescription>
              </Alert>
            ) : (
              <p className="text-gray-600">
                Sign in with just your email - no password required!
              </p>
            )}
          </div>

          <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
            <Button
              variant="ghost"
              size="lg"
              onClick={handleGoogle}
              type="button"
              className="w-full flex border-black bg-white items-center justify-center gap-3 border hover:bg-gray-50"
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_191_13499)">
                  <path
                    d="M19.999 10.2217C20.0111 9.53428 19.9387 8.84788 19.7834 8.17737H10.2031V11.8884H15.8266C15.7201 12.5391 15.4804 13.162 15.1219 13.7195C14.7634 14.2771 14.2935 14.7578 13.7405 15.1328L13.7209 15.2571L16.7502 17.5568L16.96 17.5774C18.8873 15.8329 19.9986 13.2661 19.9986 10.2217"
                    fill="#4285F4"
                  />
                  <path
                    d="M10.2055 19.9999C12.9605 19.9999 15.2734 19.111 16.9629 17.5777L13.7429 15.1331C12.8813 15.7221 11.7248 16.1333 10.2055 16.1333C8.91513 16.1259 7.65991 15.7205 6.61791 14.9745C5.57592 14.2286 4.80007 13.1801 4.40044 11.9777L4.28085 11.9877L1.13101 14.3765L1.08984 14.4887C1.93817 16.1456 3.24007 17.5386 4.84997 18.5118C6.45987 19.4851 8.31429 20.0004 10.2059 19.9999"
                    fill="#34A853"
                  />
                  <path
                    d="M4.39899 11.9777C4.1758 11.3411 4.06063 10.673 4.05807 9.99996C4.06218 9.32799 4.1731 8.66075 4.38684 8.02225L4.38115 7.88968L1.19269 5.4624L1.0884 5.51101C0.372763 6.90343 0 8.4408 0 9.99987C0 11.5589 0.372763 13.0963 1.0884 14.4887L4.39899 11.9777Z"
                    fill="#FBBC05"
                  />
                  <path
                    d="M10.2059 3.86663C11.668 3.84438 13.0822 4.37803 14.1515 5.35558L17.0313 2.59996C15.1843 0.901848 12.7383 -0.0298855 10.2059 -3.6784e-05C8.31431 -0.000477834 6.4599 0.514732 4.85001 1.48798C3.24011 2.46124 1.9382 3.85416 1.08984 5.51101L4.38946 8.02225C4.79303 6.82005 5.57145 5.77231 6.61498 5.02675C7.65851 4.28118 8.9145 3.87541 10.2059 3.86663Z"
                    fill="#EB4335"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_191_13499">
                    <rect width="20" height="20" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              Sign in with Google
            </Button>
            <div className="relative">
              <div className="absolute inset-0 flex items-center">
                <span className="w-full border-t"></span>
              </div>
              <div className="relative flex justify-center text-xs uppercase">
                <span className="bg-gray-50 px-2 text-gray-500">
                  Or continue with
                </span>
              </div>
            </div>

            <div className="space-y-2">
              <label className="block font-medium text-gray-700">Email</label>
              <div className="relative">
                <input
                  type="email"
                  {...register('email')}
                  placeholder="Enter your email"
                  className="w-full rounded-lg border border-gray-600 px-4 py-3 text-black outline-none placeholder:text-gray-600 focus:border-primary focus:ring-1 focus:ring-primary"
                />
                <span className="absolute right-4 top-3.5 text-gray-800">
                  <svg
                    width="22"
                    height="22"
                    viewBox="0 0 22 22"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g opacity="0.5">
                      <path d="M19.2516 3.30005H2.75156C1.58281 3.30005 0.585938 4.26255 0.585938 5.46567V16.6032C0.585938 17.7719 1.54844 18.7688 2.75156 18.7688H19.2516C20.4203 18.7688 21.4172 17.8063 21.4172 16.6032V5.4313C21.4172 4.26255 20.4203 3.30005 19.2516 3.30005ZM19.2516 4.84692C19.2859 4.84692 19.3203 4.84692 19.3547 4.84692L11.0016 10.2094L2.64844 4.84692C2.68281 4.84692 2.71719 4.84692 2.75156 4.84692H19.2516ZM19.2516 17.1532H2.75156C2.40781 17.1532 2.13281 16.8782 2.13281 16.5344V6.35942L10.1766 11.5157C10.4172 11.6875 10.6922 11.7563 10.9672 11.7563C11.2422 11.7563 11.5172 11.6875 11.7578 11.5157L19.8016 6.35942V16.5688C19.8703 16.9125 19.5953 17.1532 19.2516 17.1532Z" />
                    </g>
                  </svg>
                </span>
              </div>
              {errors.email && (
                <p className="text-red-500 text-sm">{errors.email.message}</p>
              )}
            </div>
            {showPassword && (
              <div className="space-y-2">
                <label className="block font-medium text-gray-700">
                  Password
                </label>
                <div className="relative">
                  <input
                    type="password"
                    {...register('password')}
                    placeholder="Enter your password"
                    className="w-full rounded-lg border border-gray-300 px-4 py-3 text-black outline-none focus:border-primary focus:ring-1 focus:ring-primary"
                  />
                  <span className="absolute right-4 top-3.5 text-gray-400">
                    <Lock className="w-6 h-6" />
                  </span>
                </div>
                {errors.email && (
                  <p className="text-red-500 text-sm">{errors.email.message}</p>
                )}
              </div>
            )}
            {serverErrors._all && (
              <p className="text-red-500 text-sm">{serverErrors._all[0]}</p>
            )}

            <Button type="submit" size="lg" className="w-full">
              {isSubmitting ? <Loading className="h-5 w-5" /> : 'Sign In'}
            </Button>
          </form>
        </div>
      </div>
    </main>
  )
}
