import { createFileRoute } from "@tanstack/react-router";
import { ProviderProfilePage } from "@/features/profile/components/ProviderProfilePage";
import { useProviderUser } from "@/hooks/useProviderUser";

const ProviderPersonalPage = () => {
  const providerUser = useProviderUser();

  return <ProviderProfilePage editable={true} user={providerUser} />;
};

export const Route = createFileRoute("/_provider/provider/profile/")({
  component: ProviderPersonalPage,
});
