import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { format } from "date-fns";
import { CalendarIcon } from "lucide-react";
import DatePicker from "react-datepicker";
import { UseFormReturn } from "react-hook-form";

export const CDSFields = ({
  form,
  index,
}: {
  form: UseFormReturn;
  index: number;
}) => {
  return (
    <div className="space-y-4">
      <FormField
        control={form.control}
        name={`certifications.${index}.state_code`}
        render={({ field }) => (
          <FormItem>
            <FormLabel>State Code</FormLabel>
            <FormControl>
              <Input {...field} maxLength={2} />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        control={form.control}
        name={`certifications.${index}.number`}
        render={({ field }) => (
          <FormItem>
            <FormLabel>CDS Number</FormLabel>
            <FormControl>
              <Input {...field} />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        control={form.control}
        name={`certifications.${index}.details`}
        render={({ field }) => (
          <FormItem>
            <FormLabel>Details</FormLabel>
            <FormControl>
              <Input {...field} />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        control={form.control}
        name={`certifications.${index}.issue_date`}
        render={({ field }) => (
          <FormItem>
            <FormLabel>Issue Date</FormLabel>
            <div className="relative">
              <DatePicker
                selected={field.value}
                onChange={(date) => field.onChange(date)}
                customInput={
                  <Input
                    value={field.value ? format(field.value, "MM-dd-yyyy") : ""}
                  />
                }
                dateFormat="MM-dd-yyyy"
                wrapperClassName="w-full"
              />
              <CalendarIcon className="absolute right-3 top-1/2 -translate-y-1/2 h-4 w-4 opacity-50 pointer-events-none" />
            </div>
            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        control={form.control}
        name={`certifications.${index}.expiration_date`}
        render={({ field }) => (
          <FormItem>
            <FormLabel>Expiration Date</FormLabel>
            <div className="relative">
              <DatePicker
                selected={field.value}
                onChange={(date) => field.onChange(date)}
                customInput={
                  <Input
                    value={field.value ? format(field.value, "MM-dd-yyyy") : ""}
                  />
                }
                dateFormat="MM-dd-yyyy"
                wrapperClassName="w-full"
              />
              <CalendarIcon className="absolute right-3 top-1/2 -translate-y-1/2 h-4 w-4 opacity-50 pointer-events-none" />
            </div>
            <FormMessage />
          </FormItem>
        )}
      />
    </div>
  );
};
