import { getEnvVar } from '@/common/utils/environment'
import { createFileRoute } from '@tanstack/react-router'
import { useSearch } from '@tanstack/react-router'
import { z } from 'zod'

const SUPABASE_URL = getEnvVar('VITE_PUBLIC_SUPABASE_URL')
const searchSchema = z.object({
  l: z.string()
    .url()
    .refine(
      (url) => url.startsWith(SUPABASE_URL),
      'Invalid redirect URL - must be data.saileapp.com'
    )
})

const SignInLinkPage = () => {
  const { l: link } = useSearch({ strict: false })
  
  return (
    <div className="min-h-screen flex flex-col items-center justify-center p-4 bg-gradient-to-b from-white to-gray-50">
      <div className="max-w-md w-full space-y-8">
        <div className="flex flex-col items-center">
          <img 
            src="/static/images/logo.png" 
            alt="Saile Logo"
            className="h-12 mb-8"
          />
          <div className="bg-white p-8 rounded-2xl shadow-sm border border-gray-100 space-y-6 w-full">
            <div className="space-y-2 text-center">
              <h1 className="text-3xl font-bold text-gray-900">Welcome!</h1>
              <p className="text-gray-600">
                Click the link below to continue to Saile
              </p>
            </div>
            <a 
              href={link}
              className="inline-flex items-center justify-center rounded-xl text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 bg-primary text-primary-foreground hover:bg-primary/90 h-12 px-8 w-full"
            >
              Continue to Saile
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export const Route = createFileRoute('/auth/signin/link/')({
  component: SignInLinkPage,
  validateSearch: searchSchema,
})
