import { supabase } from "@/ajax/clients/supabase";
import { AuthError, AuthOtpResponse, Session, User, WeakPassword } from "@supabase/supabase-js";

export const signInWithPassword = async ({
  email,
  password,
}: {
  email: string;
  password: string;
}): Promise<{ error: AuthError | null; data: {
  user: User;
  session: Session;
  weakPassword?: WeakPassword | undefined;
} | {
  user: null;
  session: null;
  weakPassword?: null;
} }> => {
  const { error, data } = await supabase.auth.signInWithPassword({
    email,
    password,
  });

  return { error, data };
};
