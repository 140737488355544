export const StatsSection = ({
  yearsOfExperience,
  certificationsCount,
  stateLicensesCount,
}: {
  yearsOfExperience: number;
  certificationsCount: number;
  stateLicensesCount: number;
}) => {
  return (
    <div className="grid grid-cols-3 gap-4 mt-4 text-center">
      {yearsOfExperience > 0 && (
        <div className="relative">
          <div className="text-xl font-bold text-blue-600">
            {yearsOfExperience}
          </div>
          <div className="text-sm text-gray-600">Years Exp.</div>
          <div className="absolute right-0 top-0 bottom-0 w-px bg-gray-200"></div>
        </div>
      )}
      {certificationsCount > 0 && (
        <div className="relative">
          <div className="text-xl font-bold text-blue-600">
            {certificationsCount}
          </div>
          <div className="text-sm text-gray-600">Certifications</div>
          {stateLicensesCount > 0 && (
            <div className="absolute right-0 top-0 bottom-0 w-px bg-gray-200"></div>
          )}
        </div>
      )}
      {stateLicensesCount > 0 && (
        <div>
          <div className="text-xl font-bold text-blue-600">
            {stateLicensesCount}
          </div>
          <div className="text-sm text-gray-600">State Licenses</div>
        </div>
      )}
    </div>
  );
};
