import { supabase } from "@/ajax/clients/supabase";
export const getFileData = async ({
  userId,
  fileId,
}: {
  userId: string;
  fileId: string;
}): Promise<Blob> => {
  const { data, error } = await supabase.storage
    .from("credentials")
    .download(`${userId}/${fileId}`);

  if (error) {
    throw error;
  }

  return data;
};
