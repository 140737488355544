"use client";
import React, { useState, useEffect, useRef } from "react";
import { updateProviderOnboardingStep } from "@/ajax/onboarding/updateProviderOnboardingStep";
import { ProviderOnboardingStep } from "@/features/onboarding/enums/ProviderOnboardingStep";
import { ProviderUser } from "@/common/types/ProviderUser";
import { Button } from "@/components/ui/button";
import { CredentialUploadMissingDialog } from "./CredentialUploadMissingDialog";
import { DocumentCategory } from "@/consts/document_categories";
import { Documents } from "@/components/Documents/Documents";
import Loading from "@/components/Loading";
import { Label } from "@/components/ui/label";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { addDays, format, parse } from "date-fns";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { sendCredentialsReminder } from "@/ajax/credentials/sendCredentialsReminder";
import { TimePicker, TimeValue } from "@/components/date-time-picker/time-picker";
import { Time } from "@internationalized/date";

const reminderSchema = z.object({
  date: z.string().min(1, "Date is required"),
  time: z.string().min(1, "Time is required")
});

type ReminderFormData = z.infer<typeof reminderSchema>;

const ReminderForm = ({ onSubmit }: { onSubmit: () => Promise<void> }) => {
  const [error, setError] = useState<string | null>(null);
  const today = new Date();
  const tomorrow = addDays(today, 1);
  const twoDaysFromNow = addDays(today, 2);
  
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting },
    watch
  } = useForm<ReminderFormData>({
    defaultValues: {
      date: format(today, "yyyy-MM-dd"),
      time: format(new Date(), "HH:mm")
    },
    resolver: zodResolver(reminderSchema)
  });
  const dateOptions = [
    { value: format(today, "yyyy-MM-dd"), label: "Today" },
    { value: format(tomorrow, "yyyy-MM-dd"), label: "Tomorrow" },
    { value: format(twoDaysFromNow, "yyyy-MM-dd"), label: format(twoDaysFromNow, "EEEE") }
  ];

  const handleFormSubmit = async (data: ReminderFormData) => {
    try {
      setError(null);
      const [hours, minutes] = data.time.split(':');
      const reminderDate = parse(data.date, 'yyyy-MM-dd', new Date());
      reminderDate.setHours(parseInt(hours));
      reminderDate.setMinutes(parseInt(minutes));

      await sendCredentialsReminder({
        remindAt: reminderDate.toISOString(),
      });
      await onSubmit();
    } catch (err) {
      setError("Failed to set reminder. Please try again.");
    }
  };

  const [hour, minute] = watch("time").split(":");
  const time = new Time(parseInt(hour), parseInt(minute), 0, 0);
  return (
    <div className="space-y-6">
      <h2 className="text-2xl font-bold text-gray-900 mb-1 animate-fade-in">
        Set a reminder to upload your documents
      </h2>
      <p className="text-gray-600">
        We'll remind you when you're ready to upload your documents. To get an immediate email with the upload link, just set the reminder time to now.
      </p>
      <form onSubmit={handleSubmit(handleFormSubmit)} className="space-y-4"> 
        <div className="space-y-2">
          <Label>Select a Date</Label>
          <Select value={watch("date")} onValueChange={(value) => setValue("date", value)}>
            <SelectTrigger className="text-xl md:text-base">
              <SelectValue placeholder="Select a day" />
            </SelectTrigger>
            <SelectContent>
              {dateOptions.map((option) => (
                <SelectItem className="text-xl" key={option.value} value={option.value}>
                  {option.label}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
          <input type="hidden" {...register("date")} />
          {errors.date && (
            <p className="text-sm text-red-500">{errors.date.message}</p>
          )}
        </div>
        <div className="space-y-2">
          <Label htmlFor="time">Reminder Time</Label>
          <TimePicker
            aria-label="Reminder Time"
            value={time}
            onChange={(time) => setValue("time", time?.toString() ?? "")}
          />
          {errors.time && (
            <p className="text-sm text-red-500">{errors.time.message}</p>
          )}
        </div>
        {error && (
          <p className="text-sm text-red-500">{error}</p>
        )}
        <div className="flex flex-col gap-3">
          <Button type="submit" className="w-full py-6" disabled={isSubmitting}>
            {isSubmitting ? <Loading className="w-4 h-4" /> : "Set Reminder"}
          </Button>
          <Button 
            type="button"
            variant="link"
            className="w-full py-6"
            onClick={onSubmit}
            disabled={isSubmitting}
          >
            Skip
          </Button>
        </div>
      </form>
    </div>
  );
};

const CredentialUploadStep = ({
  onComplete,
  providerUser,
}: {
  onComplete: () => void;
  providerUser: ProviderUser;
}) => {
  const [showDialog, setShowDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [hasDocuments, setHasDocuments] = useState<boolean | null>(null);
  const [missingDocuments, setMissingDocuments] = useState<
    DocumentCategory[] | null
  >(null);
  const documentsRef = useRef<{
    getMissingDocuments: () => DocumentCategory[];
  } | null>(null);

  useEffect(() => {
    if (documentsRef.current) {
      setMissingDocuments(documentsRef.current.getMissingDocuments());
    }
  }, []);

  const handleFinishedUploading = async () => {
    setIsLoading(true);
    try {
      const missingDocuments = documentsRef.current?.getMissingDocuments();
      if (!missingDocuments) {
        return;
      }
      setMissingDocuments(missingDocuments);
      if (missingDocuments.length > 0) {
        setShowDialog(true);
      } else {
        await handleComplete();
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleComplete = async () => {
    await updateProviderOnboardingStep(ProviderOnboardingStep.UPLOAD_DOCUMENTS);
    onComplete();
  };

  if (hasDocuments === null) {
    return (
      <div className="space-y-8">
        <h2 className="text-2xl font-bold text-gray-900 mb-3 animate-fade-in">
          Do you have your credentialing documents on this device?
        </h2>
        <div className="flex flex-col gap-4">
          <Button 
            variant="outline"
            onClick={() => setHasDocuments(true)}
            className="flex-1 py-4 border-2 text-lg"
          >
            Yes
          </Button>
          <Button 
            variant="outline"
            onClick={() => setHasDocuments(false)}
            className="flex-1 py-4 text-lg border-2 hover:bg-gray-50"
          >
            No
          </Button>
        </div>
      </div>
    );
  }

  if (hasDocuments === false) {
    return <ReminderForm onSubmit={handleComplete} />;
  }

  return (
    <div>
      <h2 className="text-2xl font-bold text-gray-900 mb-1 animate-fade-in">
        Upload Your Credentialing Documents
      </h2>
      <p className="text-gray-600 mb-8 animate-fade-in-delay-2">
        We will help you renew your documents and get you credentialed at all
        our facilities.
      </p>
      <Documents providerUser={providerUser} ref={documentsRef} />
      <Button
        size="none"
        className="w-full mt-8 py-3"
        onClick={handleFinishedUploading}
        disabled={isLoading}
      >
        {isLoading ? <Loading className="w-4 h-4" /> : "Finished Uploading"}
      </Button>
      {showDialog && missingDocuments && (
        <CredentialUploadMissingDialog
          missingDocuments={missingDocuments}
          onContinue={handleComplete}
          onBack={() => setShowDialog(false)}
        />
      )}
    </div>
  );
};

export default CredentialUploadStep;
