import { AlertCircle, Loader2 } from "lucide-react";
import { useState } from "react";

import { Alert } from "@/components/ui/alert";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import Loading from "@/components/Loading";

export function CredentialUploadMissingDialog({
  onContinue,
  onBack,
  missingDocuments,
}: {
  onContinue: () => void;
  onBack: () => void;
  missingDocuments: {
    name: string;
  }[];
}) {
  const [isLoading, setIsLoading] = useState(false);

  const handleContinue = async () => {
    setIsLoading(true);
    try {
      await onContinue();
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Dialog
      open={true}
      onOpenChange={(open) => {
        if (!open) {
          onBack();
        }
      }}
    >
      <DialogContent className="sm:max-w-xl w-[95vw] max-w-[95vw]">
        <DialogHeader>
          <DialogTitle className="text-xl font-semibold">
            Missing Documents
          </DialogTitle>
        </DialogHeader>
        <div className="space-y-4 overflow-y-auto max-h-[calc(80vh-200px)]">
          <p className="text-gray-700">
            You haven't uploaded the following documents:
          </p>
          <ul className="list-disc pl-5 space-y-1">
            {missingDocuments.map((doc, index) => (
              <li key={index} className="text-gray-600 break-words">
                {doc.name}
              </li>
            ))}
          </ul>
          <Alert>
            <AlertCircle className="h-5 w-5 flex-shrink-0 float-left mr-2 mt-0.5" />
            <div>
              <p className="font-semibold">
                Important. Without these files you will be missing out on:
              </p>
              <ul className="list-disc pl-5 mt-1">
                <li>Notifications when these credentials expire</li>
                <li>Getting credentialed at all our facilities</li>
              </ul>
              <p className="mt-1">
                You can always upload these documents later in the dashboard.
              </p>
            </div>
          </Alert>
        </div>
        <DialogFooter className="mt-6 flex flex-col xl:flex-row gap-3">
          <Button className="w-full sm:w-auto" onClick={onBack}>
            Upload remaining documents
          </Button>
          <Button
            className="w-full sm:w-auto"
            variant="outline"
            onClick={handleContinue}
            disabled={isLoading}
          >
            {isLoading ? (
              <Loading className="w-4 h-4" />
            ) : (
              "Continue without uploading"
            )}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
