import { ProviderUser } from "@/common/types/ProviderUser";
import { useSuspenseQuery } from "@tanstack/react-query";
import { providerUserQueryOptions } from "../ajax/queries";

function useProviderUser(
  options: Parameters<typeof providerUserQueryOptions>[0] = {},
): ProviderUser {
  const query = useSuspenseQuery(providerUserQueryOptions(options));
  return query.data;
}

export { useProviderUser };
