import { differenceInDays, parse } from "date-fns";

export enum ExpireState {
  VALID = "valid",
  WARNING = "warning",
  EXPIRING = "expiring",
}

export const getExpireState = (date: Date) => {
  const now = new Date();
  const daysUntilExpiry = differenceInDays(date, now);

  if (daysUntilExpiry <= 14) {
    return ExpireState.EXPIRING;
  } else if (daysUntilExpiry <= 60) {
    return ExpireState.WARNING;
  }
  return ExpireState.VALID;
};
