import { Bell, Briefcase, FileText } from "lucide-react";

export const PitchContent = () => (
  <>
    <h1 className="text-4xl font-bold mb-6">Saile Features</h1>
    <div className="space-y-6">
      <div className="flex items-start space-x-3 animate-fade-in-delay-1">
        <div className="flex-shrink-0 mt-1">
          <Briefcase className="w-6 h-6" />
        </div>
        <div>
          <h3 className="font-semibold text-xl">Spam-Free Healthcare Shifts</h3>
          <div className="mt-1 mb-2">
            <span className="bg-green-100 text-green-800 text-xs font-medium px-2.5 py-0.5 rounded-full">
              Coming Soon
            </span>
          </div>
          <p className="text-gray-100">
            No more recruiter spam - browse high-paying shifts from healthcare
            facilities in your area, on your terms
          </p>
        </div>
      </div>
      <div className="flex items-start space-x-3 animate-fade-in-delay-2">
        <div className="flex-shrink-0 mt-1">
          <FileText className="w-6 h-6" />
        </div>
        <div>
          <h3 className="font-semibold text-xl">Secure Digital Storage</h3>
          <p className="text-gray-100">
            Store all your medical licenses, certifications, and CME credits in
            one secure place
          </p>
        </div>
      </div>
      <div className="flex items-start space-x-3 animate-fade-in-delay-3">
        <div className="flex-shrink-0 mt-1">
          <Bell className="w-6 h-6" />
        </div>
        <div>
          <h3 className="font-semibold text-xl">Never Miss a Deadline</h3>
          <p className="text-gray-100">
            Smart notifications keep you ahead of renewal deadlines. Discounts
            on renewals available.
          </p>
        </div>
      </div>
    </div>
    <img
      src="/static/images/renewal_app_screenshot.png"
      alt="Saile app dashboard"
      className="rounded-lg shadow-lg w-full mt-6"
    />
  </>
);
