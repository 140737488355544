import { supabase } from "@/ajax/clients/supabase";
export const getHealthcareRole = async (id: string) => {
  // Get healthcare roles from supabase
  const { data, error } = await supabase
    .from("healthcare_professional_type")
    .select("id, name, category, code")
    .eq("id", id)
    .maybeSingle();

  if (error) throw error;

  return data;
};
