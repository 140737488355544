import { Button } from "@/components/ui/button";
import { useState } from "react";
import { SharingProfileModal } from "./SharingProfileModal";
import { Share } from "lucide-react";

export function SharingProfileButton() {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Button size="sm" variant="outline" onClick={() => setOpen(true)}>
        <Share className="w-4 h-4 mr-2" />
        Share Profile
      </Button>
      <SharingProfileModal open={open} onOpenChange={setOpen} />
    </>
  );
}
