import { apiRequestPostWithRetry } from "../utils/apiRequest";

type ShareProfileResponse = {
  link: string;
};
export const shareProfile = async ({
  email,
  includeFiles,
}: {
  email: string;
  includeFiles: boolean;
}): Promise<ShareProfileResponse> => {
  const response = await apiRequestPostWithRetry({
    path: "/profile/share",
    body: {
      email,
      includeFiles: includeFiles,
    },
  });
  return response.data;
};
