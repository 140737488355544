import { getEnvVar } from "@/common/utils/environment";
import { createClient } from "@supabase/supabase-js";
import { Capacitor } from "@capacitor/core";

export const STORAGE_KEY = "sb.auth.token";

const createSupabaseClient = () => {
  const expiryMinutes = parseInt(getEnvVar("VITE_PUBLIC_TOKEN_EXPIRY_MINUTES") || "60");
  const isNative = Capacitor.isNativePlatform();

  const client = createClient(
    getEnvVar("VITE_PUBLIC_SUPABASE_URL"),
    getEnvVar("VITE_PUBLIC_SUPABASE_ANON_KEY"),
    {
      auth: {
        storageKey: STORAGE_KEY,
        storage: {
          getItem: (key) => {
            const item = localStorage.getItem(key);
            if (!item) return null;

            const { value, timestamp } = JSON.parse(item);
            if (!isNative && expiryMinutes > 0 && Date.now() - timestamp > expiryMinutes * 60 * 1000) {
              supabase.auth.signOut();
            }
            
            return value;
          },
          setItem: (key, value) => {
            const item = JSON.stringify({
              value,
              timestamp: Date.now()
            });
            return localStorage.setItem(key, item);
          },
          removeItem: (key) => {
            return localStorage.removeItem(key);
          },
        },
      },
    },
  );
  return client;
};

export const supabase = createSupabaseClient();

export const getAccessToken = async (): Promise<string | null> => {
  const token = await supabase.auth.getSession();

  return token.data.session?.access_token ?? null;
};
