"use client";
import React, { useEffect, useMemo, useState } from "react";
import Welcome from "./WelcomeStep";
import { useProviderUser } from "@/hooks/useProviderUser";
import OnboardingStepper from "./OnboardingStepper";
import { useNavigate } from "@tanstack/react-router";
import { getOnboardingSteps } from "../utils/getOnboardingSteps";
import { PROVIDER_USER_QUERY_KEY } from "@/ajax/queries";
import { queryClient } from "@/ajax/queryClient";

const OnboardingContainer = () => {
  const [step, setStep] = useState<number>(0);
  const providerUser = useProviderUser();
  const navigate = useNavigate();

  const onboardingSteps = useMemo(
    () => getOnboardingSteps(providerUser),
    [getOnboardingSteps],
  );

  const onComplete = () => {
    if (step == null) return;

    if (step === onboardingSteps.length - 1) {
      queryClient.invalidateQueries({
        queryKey: [PROVIDER_USER_QUERY_KEY],
      });
      // If we are at the last step, redirect to the provider dashboard
      navigate({
        to: "/provider/home",
      });
      return;
    }

    setStep(step + 1);
  };
  const stepData = onboardingSteps[step];

  const Component = step == null ? step : stepData?.component;

  if (!providerUser) {
    return null;
  }

  if (Component === Welcome) {
    return <Welcome onComplete={onComplete} />;
  }

  return (
    <div className="bg-gray-100 lg:p-2 lg:pt-4 md:pt-8 md:p-8 min-h-screen">
      <div className="grid grid-cols-12 lg:gap-4 lg:px-4">
        <div className="col-span-12 lg:col-span-4">
          <div
            className={`bg-white p-8 lg:rounded-lg lg:border animate-fade-in-delay-1 ${stepData?.hideStepper ? "hidden lg:block" : ""}`}
          >
            <OnboardingStepper
              onboardingSteps={onboardingSteps}
              stepIndex={step}
            />
          </div>
        </div>
        <div className="col-span-12 lg:col-span-8">
          <div
            className={`animate-fade-in-delay-2 w-full max-w-7xl p-4 ${stepData?.hideStepper ? "py-12" : "pb-12"} md:p-8 lg:rounded-lg lg:border bg-white`}
          >
            {Component != null && (
              <Component providerUser={providerUser} onComplete={onComplete} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OnboardingContainer;
