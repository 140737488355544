export const generateDocumentName = (versionSource: {
  file_name: string;
  generated_title: string | null;
}) => {
  const extension = versionSource.file_name.split(".").pop();
  const fileName = versionSource.generated_title
    ? `${versionSource.generated_title}.${extension}`
    : versionSource.file_name;
  return fileName;
};
